import React, { useEffect, useState } from 'react';
import { LanguageService } from '@apiService/tokenService';
import GenericService from '@apiService/GenericService';

export const globalConfigContext = React.createContext(); 

const isArabic = () => {
    const language = LanguageService.getLanguage();
    if (language == 'ar' || language == 'ab') {
        return true;
    } else {
        return false;
    }
}

const GlobalConfigContext = (props) => {
    const setMerchantIdAndAccessCode = (merchant_id, access_code) => {
        if(!merchant_id) return;
        setState(prev => ({
            ...prev,
            payment_data: {
                ...prev.payment_data,
                merchant_identifier: merchant_id,
                access_code
            }
        }));
    }

        
    const setShowPromotionPopup = (val) => {
        setState(prev => ({
            ...prev,
            showPromotionPopup: val
        }))
    } 

    const refreshPageContent = () => {
        setState(prev => ({
            ...prev,
            shouldRefreshToken: Math.random().toFixed(5)
        }));
    }

    const [state, setState] = useState({
        arabic: isArabic(),
        country_selector_url: null,
        currency: {},
        payment_data: {},
        country_data: {},
        site_data: {},
        setMerchantIdAndAccessCode,
        shouldRefreshToken: Math.random().toFixed(5),
        refreshPageContent,
        showPromotionPopup: 0,
        setShowPromotionPopup
    });

    useEffect(() => {
        GenericService.GetCountrySpecificData().then(response => {
            if(response && response.status === 200) {
                const data = response.data.data;
                setState(prev => ({
                    ...prev,
                    country_data: {
                        country_code: data.country_code, 
                        country_id: data.country_id, 
                        country_name: data.country_name, 
                        country_short_code: data.country_short_code,
                        website_url: data.website_url,
                        is_under_maintainance: data.is_under_maintainance
                    },
                    currency: {
                        currency_name: data.currency_name,
                        currency_short_code: data.currency_short_code,
                        currency_short_code_ar: data.currency_short_code_ar,
                        currency_symbol: data.currency_symbol,
                        divided_by: data.divided_by,
                    },
                    payment_data: {
                        access_code: data.access_code,
                        merchant_identifier: data.merchant_identifier,
                        payment_url: data.payment_url
                    },
                    country_selector_url: data.country_selector_url
                }));
            }
        });

        GenericService.GetSiteData().then((response) => {
            if(response && response.status === 200) {
                const { data } = response.data;
                const newSiteData = {};

                data.forEach(({key, value}) => {
                    newSiteData[key] = value;
                });

                setState(prev => ({
                    ...prev,
                    site_data: newSiteData
                }))
            }
        }).catch(err => {
            console.error("unable to fetch site data")
        })
    }, []);

    return (
        <globalConfigContext.Provider value={state}>
            { props.children }
        </globalConfigContext.Provider>
    )
}

export default GlobalConfigContext;