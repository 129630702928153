export const FETCH_USER = 'FETCH_USER';
export const LOGIN_USER_LOADING = 'LOGIN_USER_LOADING';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT = 'LOGOUT';
export const CURRENT_USER_VERIFIED = 'CURRENT_USER_VERIFIED';
export const SIGNUP_USER_LOADING = 'SIGNUP_USER_LOADING';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_SUCCESS_NEW = 'SIGNUP_USER_SUCCESS_NEW';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const ADD_USER = 'ADD_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const SET_TEMP_USER = 'SET_TEMP_USER';
export const SET_GUEST_USER = 'SET_GUEST_USER';
export const SET_GUEST_ERROR = 'SET_GUEST_ERROR';
export const SET_USER_BOOKINGS = 'SET_USER_BOOKINGS';
export const REMOVE_MIGRATED_USER_DATA = 'REMOVE_MIGRATED_USER_DATA';
export const REMOVE_GUEST_USER = 'REMOVE_GUEST_USER';
