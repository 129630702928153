import Api from './Api';

export default {
    GetAllFnbCategories(payload) {
        return Api().get(`fnb/categories/${payload.cinema_id}`)
    },
    GetAllFnbItems(payload) {
        return Api().get(`fnb/fnb-items/${payload.cinema_id}/${payload.category_id}/?is_only_booking_fee=${payload?.is_only_booking_fee}`)
    },
    GetModifierGroup(payload) {
        return Api().get(`fnb/modifier-group/${payload.cinema_id}/${payload.item_id}`)
    },
    GetAllModifiers(payload) {
        return Api().get(`fnb/modifiers/${payload.cinema_id}/${payload.item_id}`)
    },
    AddFnb(payload) {
        return Api().get(`fnb/add-fnb`, payload)
    },
    GetRecipeItems(payload) {
        return Api().get(`fnb/recipe-items/${payload.cinema_id}/${payload.item_id}`);
    }
}