import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';

/* 
Even though it is named as error popup, this is adapted to be used as a general purpose
Popup with custom title, subtitle, children, action, etc.

If children are provided, it overrides the subtitle
*/

function ErrorPopup({ show, action, title, subtitleTwo,subtitle=null, buttonText, showEmoji=true, close=false, onClose=null, children, centered=true, showEmoji1=true }) {
    return (
        <Modal
          show={show}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="disclaimer-popup"
          size="md"
          backdrop="static"
          onHide={onClose ? onClose : null}
        >
          <Modal.Header className="position-relative flex-column" closeButton={close}>
            {title && <h2 className="title w-100">{title}</h2>}
            {/* {showEmoji1 && <p className="mx-auto frs-32 mt-4">&#128577;</p>} */}
          </Modal.Header>
          
          <Modal.Body>
            <div className="message border-grey-botto">
              {
                children 
                  ? children
                  :<div> <p className="px-md-4">{subtitle}</p> <p className="px-md-1">{subtitleTwo}</p> </div>
              }
            </div>
              {buttonText && <button className={`btn blue-btn px-5 mt-4 btn-center`}
                onClick={action}>
                {buttonText}
              </button>}
          </Modal.Body>
        </Modal>
    )
}

export default ErrorPopup;