import Api from './Api';
import { createQueryString } from './ApiHelper';
import {getCountryId} from '../helper/getCountryData'

export default {
    GetPromotionsOffer(payload) {
        payload.country_id = getCountryId()
        let query = createQueryString(payload);
        const randomNumber = Math.floor(100 + Math.random() * 900);
        return Api().get(`promotions/promotions/${query}&gcc=${randomNumber}`);
    },
    ValidateBankOffer(payload) {
        return Api().post(`promotions/isCardValid/`, payload);
    },
    GetCardDetails(payload) {
        return Api().post(`promotions/cardDetails/`, payload);
    },
    SubmitReceipt(payload) {
        return Api().post(`promotions/submit-receipt-oman/`, payload);
    },
    SubmitReceiptAlOsraBahrain(payload) {
        return Api().post(`promotions/submit-receipt-al-osra-bahrain/`, payload);
    },
    SubmitReceiptDammamMicrosite(payload) {
        return Api().post(`promotions/submit-receipt-dammam-microsite/`, payload);
    },
    SubmitReceiptOmanMicrositeAll(payload) {
        return Api().post(`promotions/submit-receipt-oman-microsite-all/`, payload);
    },
    SubmitReceiptUaeMicrosite(payload) {
        return Api().post(`promotions/submit-receipt-uae-microsite/`, payload);
    },
}