import {
  FETCH_SEAT_LAYOUT_LOADING,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SHOW_DETAILS_SUCCESS,
  SET_SEAT_SELECTED,
  SET_OFFER_SEAT_SELECTED,
  SET_RESERVATION_ID,
  SET_SEAT_SELECT_ERROR,
  SET_TRANSACTION_ID,
  SET_TRANSACTION_ERROR,
  CLEAR_BOOKING_STATE,
  SET_TICKET_PRICE,
  RESET_RESERVATION_ID,
  SET_GUEST_ID,
  START_TIMER,
  STOP_TIMER,
  SET_BOOKING_FEE_DATA,
  SET_ADDITIONAL_DATA,
  CLEAR_ERRORS,
  CLEAR_FOOD_AND_BEVERAGES_STATE,
  CLEAR_SELECTED_SEATS,
  CLEAR_TIMEOUT_POPUP,
  SET_VOUCHER_CODE_SUCCESS,
  SET_VOUCHER_CODE_ERROR,
  SET_SESSION_DETAILS,
  SET_BANK_OFFERS_LIST,
  SET_SELECTED_BANK_OFFER,
  REMOVE_SELECTED_BANK_OFFER,
  SET_REMAINING_SEATS_LIMIT,
  CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS,
  SET_OFFERTYPE
} from "./bookingTypes";

import BookingService from "@apiService/BookingService";
import PromotionsService from "@apiService/PromotionsService";
import { setTaxPercentInFnb } from "../foodAndBeverages/foodAndBeveragesActions";

let orderExpiryReset_interval;
let orderExpiryReset_timesReset = 0;

var timerInterval = null;

const fetchSeatLayoutLoading = () => {
  return {
    type: FETCH_SEAT_LAYOUT_LOADING,
  };
};

const fetchSeatLayoutSuccess = (data, isVoucherAvailable, offersDataUpdated) => {
  return {
    type: FETCH_SEAT_LAYOUT_SUCCESS,
    payload: data,
    isVoucherAvailable,
    offersDataUpdated
  };
};

const fetchSeatLayoutError = (err) => {
  return {
    type: FETCH_SEAT_LAYOUT_ERROR,
    payload: err,
  };
};

export const setOfferType = (data) => {
  return {
    type: SET_OFFERTYPE,
    payload: data,
  };
};

const fetchShowDetailsSuccess = (data) => {
  return {
    type: FETCH_SHOW_DETAILS_SUCCESS,
    payload: data,
  };
};

const setSeatsSelected = (payload) => {
  return {
    type: SET_SEAT_SELECTED,
    payload: payload,
  };
};

const setOfferSeatsSelected = (payload) => {
  return {
    type: SET_OFFER_SEAT_SELECTED,
    payload: payload,
  };
};

const setReservationID = (payload) => {
  return {
    type: SET_RESERVATION_ID,
    payload: payload,
  };
};
export const resetReservationID = () => {
  return {
    type: RESET_RESERVATION_ID,
    payload: {},
  };
};

export const selectSeats = (payload) => {
  return function (dispatch) {
    dispatch(setSeatsSelected(payload));
  };
};

export const selectOfferSeats = (payload) => {
  console.log(payload, "selectOfferSeats");
  return function (dispatch) {
    dispatch(setOfferSeatsSelected(payload));
  };
};

export const setSeatSelectError = (error) => ({
  type: SET_SEAT_SELECT_ERROR,
  payload: error,
});

export const setTransactionId = (payload) => ({
  type: SET_TRANSACTION_ID,
  payload,
});

export const setTransactionError = (error) => ({
  type: SET_TRANSACTION_ERROR,
  payload: error,
});

export const clearBookingState = () => {
  clearOrderResetInterval();
  return {
    type: CLEAR_BOOKING_STATE,
  };
};

export const clearFoodAndBeveragesState = () => ({
  type: CLEAR_FOOD_AND_BEVERAGES_STATE,
});

export const clearCinepolisBankVoucherOffers = () => ({
  type: CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS,
});

const setTicketPrice = (payload) => ({
  type: SET_TICKET_PRICE,
  payload,
});

const setGuestId = (payload) => ({
  type: SET_GUEST_ID,
  payload,
});

const startTimer = () => ({
  type: START_TIMER,
  payload: timerInterval,
});

export const resetTimer = () => ({
  type: STOP_TIMER,
});

const setBookingFeeData = (payload) => ({
  type: SET_BOOKING_FEE_DATA,
  payload,
});

const setAdditionalData = (payload) => ({
  type: SET_ADDITIONAL_DATA,
  payload,
});

export const clearSeatLayoutErrors = () => ({
  type: CLEAR_ERRORS,
});

export const clearSelectedSeats = () => ({
  type: CLEAR_SELECTED_SEATS,
});

export const setVoucherCodeSuccess = (payload) => ({
  type: SET_VOUCHER_CODE_SUCCESS,
  payload,
});

export const setVoucherCodeError = (payload) => ({
  type: SET_VOUCHER_CODE_ERROR,
  payload,
});

export const setSessionDetails = (payload) => ({
  type: SET_SESSION_DETAILS,
  payload,
});

export const stopTimer = () => {
  return function (dispatch) {
    clearInterval(timerInterval);
    timerInterval = null;
    dispatch(resetTimer());
  };
};

export const clearTimeoutPopup = () => ({
  type: CLEAR_TIMEOUT_POPUP,
});

export const setSelectedBankOffer = (payload) => ({
  type: SET_SELECTED_BANK_OFFER,
  payload,
});

export const removeSelectedBankOffer = (payload) => ({

  type: REMOVE_SELECTED_BANK_OFFER,
});

export const setRemainingSeats = (payload) => ({
  type: SET_REMAINING_SEATS_LIMIT,
  payload,
});

export const setFinalBankOfferObjects = (payload) => (dispatch) => {
  console.log(payload, "payloadpayloadpayload")
  const {
    selectedBankOfferTicket,
    selectedBankOfferCardObj,
    bankOfferUserCardDetails,
    remaining_limit,
  } = payload;

  dispatch(setSelectedBankOffer(payload));
  dispatch(setRemainingSeats(remaining_limit));
};

export const removeFinalBankOfferObjects = (payload) => (dispatch) => {
  // console.log(payload, "payloadpayloadpayload")
  // const {
  //   selectedBankOfferTicket,
  //   selectedBankOfferCardObj,
  //   bankOfferUserCardDetails,
  //   remaining_limit,
  // } = payload;

  console.log("removeFinalBankOfferObjects")
  dispatch(removeSelectedBankOffer());
  // dispatch(setRemainingSeats(remaining_limit));
};

export const validateSelectedBankOffer = (payload) => {
  return async function (dispatch) {
    const {
      selectedBankOfferTicket,
      selectedBankOfferCardObj,
      cinema_id,
      session_id,
    } = payload;
    const { firstSixDigits, lastFourDigits } = payload.bankOfferUserCardDetails;

    try {
      const response = await PromotionsService.ValidateBankOffer(payload);
      console.log("bank offer validation resp", response.data);
      return response.data;

      // if(data.remaining_limit !== 0 && data.remaining_limit > 0) {
      //   dispatch(setSelectedBankOffer(payload));
      //   dispatch(setRemainingSeats(data.remaining_limit));
      //   return { status: true };
      // } else {
      //   return { status: false, message: data.message };
      // }
    } catch (err) {
      throw err;
    }
  };
};

export const fetchSeatLayoutData = (payload) => {
  return function (dispatch) {
    dispatch(fetchSeatLayoutLoading());
    BookingService.GetSeatLayout(payload)
      .then((response) => {
        const { data } = response;
        console.log(data, "fffffffffffffffffffffffffffffff")
        if (
          data.status &&
          data.data.seatPlan.SeatLayoutData &&
          data.data.seatPlan.SeatLayoutData.Areas &&
          data.data.seatPlan.SeatLayoutData.Areas.length > 0
        ) {
          dispatch(
            fetchSeatLayoutSuccess(
              data.data.seatPlan.SeatLayoutData.Areas,
              data.data.isVoucherAvailable,
              data.data.cinepolisOffersUpdated
            )
          );
          dispatch(fetchShowDetailsSuccess(data.data.showDetails));
          dispatch(
            setBookingFeeData(data.data.showDetails[0].booking_fee_in_cents)
          );
          if (
            (data.data.showDetails[0].fnb_tax_percent !== null) |
            (data.data.showDetails[0].fnb_tax_percent !== undefined)
          ) {
            dispatch(
              setTaxPercentInFnb(data.data.showDetails[0].fnb_tax_percent)
            );
          }
          if (data.data.covidMessage) {
            dispatch(
              setAdditionalData({ covidMessage: data.data.covidMessage })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(fetchSeatLayoutError(err.message));
      });
  };
};

export const timerOnOffer = (

) => {
  return async (dispatch) => {
    timerInterval = setInterval(() => {
      dispatch(startTimer());
    }, 1000);
  };
};

export const vistaSetSeats = (
  {
    cinema_id,
    seats_name,
    user_selected_seats,
    session_id,
    selectedTicketPrice,
    userId,
    isGuest,
    voucher_data,
    selectedBankOfferCardObj,
    selectedBankOfferTicket,
    offerType,
    bankOfferUserCardDetails,
    is_ladies_night,
    is_cinepolis_pkg_offer_applid,
    selectedRamadanOffers,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      const response = await BookingService.SelectSeats({
        cinema_id,
        seats_name,
        user_selected_seats,
        session_id,
        selectedTicketPrice,
        userId,
        isGuest,
        voucher_data,
        selectedBankOfferCardObj,
        selectedBankOfferTicket,
        offerType,
        bankOfferUserCardDetails,
        is_ladies_night,
        is_cinepolis_pkg_offer_applid,
        selectedRamadanOffers,
      });
      const { data } = response;
      console.log(data, "=JFSDAFJLDF")
      if (data && data.status) {
        console.log("DATATRARs", data)
        dispatch(setReservationID(data.data.reservation_id));
        dispatch(setBookingFeeData(data.data.booking_fee));
        dispatch(setSessionDetails({ session_id, cinema_id }));
        // dispatch(initiateOrderExpiryReset({ reservation_id: data.data.reservation_id }));
        timerInterval = setInterval(() => {
          dispatch(startTimer());
        }, 1000);
        callback(data.data.reservation_id);
      }
    } catch (error) {
      if (error.response) {
        dispatch(setSeatSelectError(error.response.data));
      } else {
        dispatch(setSeatSelectError({ message: error.message }));
      }
    } finally {
    }
  };
};

export const cancelTransaction = (payload) => {
  return function (dispatch) {
    BookingService.CancelTransaction(payload)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          console.log("data", data);
        }
      })
      .catch((err) => {
        console.log("Cancel Transaction Error", err.message);
      });
  };
};

/* Not in use */
export const performCheckout = (payload) => {
  return function (dispatch) {
    BookingService.BookSeats(payload)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          dispatch(setTransactionId(data.data));
          dispatch(clearBookingState());
          dispatch(clearFoodAndBeveragesState());
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setTransactionError(err.response.data.message));
        } else {
          dispatch(setTransactionError(err.message));
        }
      });
  };
};

export const fetchShowDetails = (payload, callback) => {
  return async function (dispatch) {
    try {
      const response = await BookingService.GetBookingDetails(payload);
      if (response && response.status === 200) {
        dispatch(fetchShowDetailsSuccess(response.data.data));
      }
      return response.data.data;
    } catch (err) {
      console.log(err);
      callback();
      throw err;
    }
  };
};

export const fetchTicketPrice = (payload) => {
  return function (dispatch) {
    BookingService.GetTicketPrice(payload)
      .then((response) => {
        if (response && response.data.data) {
          dispatch(setTicketPrice(response.data.data));
          dispatch(setBankOffersList(payload));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const reserveFnbItems = (payload) => {
  return async function (dispatch) {
    try {
      const response = await BookingService.SelectFnbItems(payload);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const validateVoucher = (payload) => {
  return async function (dispatch) {
    try {
      const response = await BookingService.ValidateVoucher(payload);
      if (response && response.data.data === "INVALID") {
        dispatch(setVoucherCodeError("error"));
      } else {
        dispatch(
          setVoucherCodeSuccess({
            ...response.data.data,
            voucher_code: payload.voucher_code,
          })
        );
      }
      console.log(response.data);
      return response.data.data;
    } catch (err) {
      console.log(err.response ? err.response : err.message);
      dispatch(setVoucherCodeError(err.message));
    }
  };
};

function clearOrderResetInterval() {
  if (
    orderExpiryReset_interval !== null &&
    orderExpiryReset_interval !== undefined
  ) {
    console.log(orderExpiryReset_interval);
    clearInterval(orderExpiryReset_interval);
    orderExpiryReset_interval = null;
    orderExpiryReset_timesReset = 0;
    console.log("cleared out order reset interval");
  }
}

export const initiateOrderExpiryReset = (payload) => () => {
  console.log("initating order reset");

  clearOrderResetInterval();

  orderExpiryReset_interval = setInterval(() => {
    BookingService.ResetOrderExpiry(payload)
      .then((resp) => {
        console.log("order reset");
        console.log(resp.data);
      })
      .catch((err) => {
        console.log("error resetting the order");
        console.log(err.response);
      });

    orderExpiryReset_timesReset += 1;

    if (orderExpiryReset_timesReset === 2) {
      clearOrderResetInterval();
      return;
    }
  }, 4 * 60 * 1000); // 4 Mins
};

export const setBankOffersList = (payload) => (dispatch) => {
  let bankOffers = [];

  BookingService.GetTicketPrice(payload, true)
    .then((response) => {
      if (response && response.data.data && response.data.data.Tickets) {
        let ticketsData = response.data.data.Tickets;

        if (
          ticketsData &&
          Array.isArray(ticketsData) &&
          ticketsData.length > 0
        ) {
          ticketsData.forEach((ticketData) => {
            if (
              ticketData.IsCardPaymentPromotionTicket &&
              ticketData.IsCardPaymentPromotionTicket === true
            ) {
              bankOffers.push(ticketData);
              console.log(bankOffers, "bankOffers >>>>>>>>>>>>>>>>>>>>")
            }
          });
        }
      }

      dispatch({
        type: SET_BANK_OFFERS_LIST,
        payload: bankOffers,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};
