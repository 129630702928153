import React, { useState, useEffect, useContext } from 'react';
import { CityService, LanguageService } from "@apiService/tokenService";
import { globalConfigContext } from "@context/GlobalConfigContext";

function useShowOscarFilmFest() {
    const { site_data } = useContext(
        globalConfigContext
      );
    const [showOscarFilmFest, setShowOscarFilmFest] = useState(false);

    const city_data = CityService.getCity() ? CityService.getCity() : {};
    const { cityId, cityName } = city_data;

    useEffect(() => {
        if(cityId && [2, 3, 6].includes(cityId) && site_data && site_data.oscar_ff === "Y") {
            setShowOscarFilmFest(true);
        } else {
            setShowOscarFilmFest(false);
        }
    }, [cityId, site_data]);

    return showOscarFilmFest;
}

export default useShowOscarFilmFest;