import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Dropdown, Modal } from "react-bootstrap";
import ChangeCityLanguagePopup from "@components/partials/ChangeCityLanguagePopup";
import { useHistory, useLocation, Link } from "react-router-dom";
import cinepolisSmall from "../../assets/pictures/cinepolis-small.png";
import { CityService, LanguageService } from "@apiService/tokenService";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";
import GenericService from "@apiService/GenericService";
import i18n from "@plugins/i18n";
import { LANDING_PAGE } from "@config";
import qs from "qs";
import { titleCase } from "@helper/formatting";
import appStore from "@assets/pictures/app-store-new.png";
import playStore from "@assets/pictures/play-store-new.png";
import cinepolisLogo from "../../assets/pictures/svgs/logo-cinepolis.svg";


import { CountryService } from "@apiService/tokenService";

function Footer({ t }) {
  const qparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { arabic, country_selector_url, shouldRefreshToken, country_data } =
    useContext(globalConfigContext);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();
  const currentPage = location.pathname.split("/")[1];
  const [modalBackdrop, setModalBackdrop] = useState(true);
  const [originalLanguage, setOriginalLanguage] = useState(null);
  const [originalCity, setOriginalCity] = useState(null);
  const [cities, setCities] = useState(null);
  console.log("city >>>>>>", cities);
  const [languages, setLanguages] = useState(null);

  const [allCountries, setAllCountries] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [show, setShow] = useState(false);

  const [device, setDevice] = useState(false);

  const dispatch = useDispatch();

  const handleShowLogin = () => {
    // dispatch(setShowLoginSignup(true));
  };

  const showCountryPage = () => {
    history.push("/select-country");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };


  useEffect(() => {
    let city_data = CityService.getCity();
    let language_data = LanguageService.getLanguage();

    console.log(city_data, "city_datacity_datacity_data");
    console.log(language_data, "language_datalanguage_datalanguage_data");

    if (language_data) {
      setOriginalLanguage(language_data)
    }
  }, []);


  useEffect(() => {
    GenericService.GetCountries()
      .then((response) => {
        if (response && response.status === 200) {
          setAllCountries(response.data.data.countries);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  useEffect(() => {
    if (cities && cities?.length > 0) {
      CityService.saveCity({
        cityId: cities[0].city_id,
        cityName: cities[0].city_name,
      });
      setOriginalCity(cities[0].city_name);
    }
  }, [cities]);

  // const regions = ['BAHRAIN', 'OMAN', 'UAE'];
  // const languages = ['ENGLISH', 'ARABIC'];

  // useEffect(() => {
  //     const city_data = CityService.getCity();
  //     const language = LanguageService.getLanguage();

  //     // if (!city_data || !language) {
  //     //     setShow(true);
  //     //     setModalBackdrop("static");
  //     // }
  // }, [])

  useEffect(() => {
    var IOS = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setDevice(IOS);
  });

  useEffect(() => {
    const city_data = CityService.getCity();
    const language = LanguageService.getLanguage();

    const city_id_from_param = parseInt(qparams.city_id);
    const lang_from_param = qparams.lang;
    const hide_popup_param = qparams.hide_popup;

    const getCitiesAndLangs = async () => {
      try {
        const citiesResp = await GenericService.GetCities();
        const languagesResp = await GenericService.GetLanguages();
        let city_obj;
        let lang_obj;

        let foundCity = false;
        let foundLang = false;

        const cities = citiesResp.data.data;
        const languages = languagesResp.data.data;

        setCities(cities);
        setLanguages(languages);

        if (city_data) {
          const { cityId, cityName } = city_data;
          let city_obj_temp = cities.find((city) => city.city_id == cityId);
          if (city_obj_temp) {
            setOriginalCity(
              arabic ? city_obj_temp.city_name_ar : city_obj_temp.city_name
            );
            foundCity = true;
            city_obj = {
              city_id: cityId,
              city_name: arabic
                ? city_obj_temp.city_name_ar
                : city_obj_temp.city_name,
            };
          }
        } else if (city_id_from_param) {
          let city_obj_t = cities.find(
            (city) => city.city_id == city_id_from_param
          );
          if (city_obj_t) {
            foundCity = true;
            setOriginalCity(city_obj_t.city_name);
            city_obj = city_obj_t;
          }
        }

        if (language || lang_from_param) {
          let lang_to_use = language ? language : lang_from_param;
          let languageObj = languages.find(
            (lang) => lang.site_lang_short_code === lang_to_use
          );
          if (languageObj) {
            lang_obj = languageObj;
            foundLang = true;
            if (languageObj.site_lang_id == 2) {
              document
                .getElementsByTagName("html")[0]
                .setAttribute("dir", "rtl");
            } else {
              document
                .getElementsByTagName("html")[0]
                .setAttribute("dir", "ltr");
            }
            // setOriginalLanguage(languageObj.site_lang);
            i18n.changeLanguage(languageObj.site_lang_short_code);
          }
        }

        if (foundCity && foundLang) {
          CityService.saveCity({
            cityId: city_obj.city_id,
            cityName: city_obj.city_name,
          });
          LanguageService.saveLanguage(lang_obj.site_lang_short_code);
        } else if (!hide_popup_param || hide_popup_param !== "yes") {
          if (cities && cities.length > 1) {
            setShow(true);
          } else {
            setShow(false);
          }
        }
      } catch (err) {
        console.log(err);
        console.log("unable to load city and languages");
      }
    };

    //if (!location.pathname.startsWith("/avail-offer-ksa")) {
    getCitiesAndLangs();
    //}

    // GenericService.GetCities().then(response => {
    //     if (response && response.data) {
    //         setCities(response.data.data);
    //     }
    // });

    // GenericService.GetLanguages().then(response => {
    //     if (response && response.data) {
    //         let languages = response.data.data;
    //         setLanguages(languages);
    //         if(language) {
    //             let languageObj = languages.find(lang => lang.site_lang_short_code === language);
    //             if (languageObj) {
    //                 if (languageObj.site_lang_id == 2) {
    //                     // require('@assets/css/arabic.css');
    //                     // lastStyleSheet.disabled = false;
    //                     document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
    //                 } else {
    //                     // lastStyleSheet.disabled = true;
    //                     document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    //                 }
    //                 setOriginalLanguage(languageObj.site_lang)
    //                 i18n.changeLanguage(languageObj.site_lang_short_code);
    //             } else {
    //                 setOriginalLanguage(null);
    //             }
    //         }
    //     }
    // })
  }, [shouldRefreshToken]);

  const switchLanguageTo = (lang) => {
    LanguageService.saveLanguage(lang);
    window.location.reload();
  };

  const switchCountryTo = (value) => {
    console.log(value, "TTTTTTTTTTTTTTTTTTTTTTTTTTT")

    let cityIdAndNameArr = value.split("TTT");

    CityService.saveCity({
      cityId: cityIdAndNameArr[0],
      cityName: cityIdAndNameArr[1],
    });

    window.location.reload();
  };

  if ([].includes(currentPage)) {
    return null;
  }

  const selectCountry = (country) => {
    // // {country.website_url ? `${getTargetUrl(country)}${isPwa ? "?ispwa=true" : ""}` : "/"}
    // let targetUrl = `${getTargetUrl(country, isPwa)}${isPwa ? "?ispwa=true" : ""
    //   }`;

    const countryArrForSave = country.split('TTT')

    if (countryArrForSave && countryArrForSave.length > 0) {
      CountryService.saveCountry({
        country_name: countryArrForSave[1],
        country_id: countryArrForSave[0],
        redirect_url: countryArrForSave[2],
      });
      window.location.href = countryArrForSave[2];
    }
  };

  console.log(window.safari, "safari")


  return (
    <div>
      <section className={"d-none d-md-block footer-main-all"}>
        <div className="custom-footer-top py-1 py-xl-3">
          <div className="container-fluid container-spacing">
            <div className="row align-items-center">
              <div className="col-md-7">
                {/* <div>
                  <figure>
                    <img
                      src={cinepolisLogo}
                      alt="cinepolis-logo"
                      className="mxh-70 img-fluid"
                    />
                  </figure>
                </div> */}
                <div>
                  <ul className="list-inline footer-links-1 font-size-small">
                    <li className="list-inline-item">
                      <Link to="/tnc">{t("footer.Terms of Service")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/privacy-policy">
                        {t("footer.Privacy Policy")}
                      </Link>
                    </li>
                    {/* <li className="list-inline-item"><a href="#" className="">{t('footer.Disclaimer')}</a></li> */}
                    <li className="list-inline-item">
                      <Link to="/feedback">{t("footer.Feedback")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/faq">{t("faq")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/about-us">{t("about-us")}</Link>
                    </li>
                    {/* <li>
                      <Link onClick={showCountryPage}> Country</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                <div>
                  <div className="d-flex">
                    {device == false ?
                      <figure>
                        <img
                          src={playStore}
                          width="138px"
                          alt="playstore"
                          className={`rounded border cursor-pointer ${arabic ? "ml-3 ml-lg-4" : "mr-3 mr-lg-4"
                            }`}
                          onClick={() =>
                          (window.location.href =
                            "https://play.google.com/store/apps/details?id=nz.co.vista.android.movie.cinepolisbahrain&hl=en")
                          }
                        />
                      </figure> : null}
                    <figure>
                      <img
                        src={appStore}
                        width="138px"
                        alt="appstore"
                        className="rounded border cursor-pointer"
                        onClick={() =>
                        (window.location.href =
                          "https://apps.apple.com/us/app/cin%C3%A9polis-gulf/id1449779192?ls=1")
                        }
                      />
                    </figure>
                  </div>
                  <div className="pt-4 d-none">
                    <div className="grid-dropdown">
                      <div>
                        <Dropdown
                          onSelect={(eventKey) => switchLanguageTo(eventKey)}
                          className="custom-dropdown-2 custom-dropdown-3"
                        >
                          <Dropdown.Toggle id="dropdown-basic">
                            {originalLanguage ? originalLanguage.toUpperCase() : null}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {

                              originalLanguage == "ar" ? (

                                <Dropdown.Item eventKey="en">{originalLanguage != null ? "EN" : ""}</Dropdown.Item>
                              ) :

                                (
                                  <Dropdown.Item eventKey="ar">{originalLanguage != null ? "AR" : ""}</Dropdown.Item>
                                )

                            }
                            {/* <Dropdown.Item eventKey="ar">Ar</Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <Dropdown
                          onSelect={(eventKey) => switchCountryTo(eventKey)}
                          className="custom-dropdown-2 custom-dropdown-4"
                        >
                          <Dropdown.Toggle id="dropdown-basic">
                            {country_data && country_data.country_short_code == "SA" ? "KSA" :
                              country_data.country_short_code == "AE" ? "UAE" :
                                country_data.country_short_code == "OM" ? "OMAN" : "BAHRAIN"

                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              allCountries && allCountries.length > 0 &&
                              allCountries
                                .filter((item) => item?.country_id != country_data?.country_id)
                                .map((item) => {
                                  return (
                                    <Dropdown.Item className={`${item?.country_id == country_data?.country_id ? "custom-dropdown-item" : ""}`} eventKey={item.country_id + 'TTT' + item.country_name + 'TTT' + item.prod_website_url}>
                                      {
                                        item.country_short_code == "SA" ? "KSA" :
                                          item.country_short_code == "AE" ? "UAE" :
                                            item.country_short_code == "OM" ? "OMAN" : "BAHRAIN"
                                      }

                                    </Dropdown.Item>
                                    // <div>Hello</div>
                                  )
                                })
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-footer-bottom py-2">
          <div className="container-fluid container-spacing">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-6">
                <div className={`${arabic ? "text-right" : ""}`}>
                  {t("footer.copyright")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-social-icons">
                  <a href="https://www.facebook.com/CinepolisGULF/"><i class="ri-facebook-fill"></i></a>
                  <a href={country_data && country_data.country_short_code == "SA" ? "https://www.instagram.com/cinepolis.ksa/?hl=en" : country_data.country_short_code == "AE" ? "https://www.instagram.com/cinepolis.uae/?hl=en" : country_data.country_short_code == "OM" ? " https://www.instagram.com/cinepolis.oman/?hl=en" : "https://www.instagram.com/cinepolis.bahrain/?hl=en"}><i class="ri-instagram-line"></i></a>
                  {/* <a href="https://twitter.com/cinepolisgulf?lang=en">&#xF3E7;</a> */}
                  <a href="https://twitter.com/cinepolisgulf?lang=en" style={{ height: "18px", width: "18px" }}>
                    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="currentColor"><path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path></svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ChangeCityLanguagePopup
        onClose={handleClose}
        showBackdrop={modalBackdrop}
        show={show}
        setShow={setShow}
      />

      {/* <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop={modalBackdrop}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-3 kumbh">
                <Modal.Header closeButton className="align-items-start pt-3 pb-2 pl-2 pr-3 pl-md-3">
                    <p className="text-light fw-bold frxl-32 frl-28 frm-24 frs-16">CHANGE REGION & LANGUAGE</p>
                </Modal.Header>
                <Modal.Footer className="flex-column align-items-start">
                    <p className="frxl-24 frl-22 frm-18 frs-14 fw-bold dls pt-3 px-2 px-md-3">CHOOSE REGION</p>
                    <div className="w-100 h-100 d-flex flex-wrap align-items-center bb-1">
                        {(cities && cities.length > 0) && cities.map((city, index) => (
                            <div key={index} className="">
                                <span
                                    className={`modal-btn px-4 m-2 m-md-3 frxl-20 frl-18 frm-16 frs-14 ${city.city_id === cityId ? "active" : ""}`}
                                    onClick={() => onSelectCity(city.city_id)}
                                >{city.city_name}</span>
                            </div>
                        ))}
                    </div>
                    <p className="frxl-24 frl-22 frm-18 frs-14 fw-bold dls pt-3 px-2 px-md-3">CHOOSE LANGUAGE</p>
                    <div className="w-100 h-100 d-flex flex-wrap align-items-center bb-1">
                        {(languages && languages.length > 0) && languages.map((language, index) => (
                            <div key={index} className="">
                                <div
                                    className={`modal-btn px-4 m-2 m-md-3 frxl-20 frl-18 frm-16 frs-14 ${language.site_lang_short_code === languageId ? "active" : ""}`}
                                    onClick={() => onSelectLanguage(language.site_lang_short_code)}
                                >{language.site_lang}</div>
                            </div>
                        ))}
                    </div>
                    {
                        showError && <p className="form-error-text d-block text-align-center my-2 mx-auto">
                            Please select both city and language!
                        </p>
                    }
                    <div className="mx-auto">
                        <a className="mxh-60 px-3 px-md-4 px-lg-5 blue-btn ls-2 frs-16 mx-auto mt-31 mb-31" href="#"
                            onClick={onConfirm}>CONFIRM</a>
                    </div>
                </Modal.Footer>
            </Modal> */}
    </div>
  );
}

export default withNamespaces()(Footer);
