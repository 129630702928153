import moment from 'moment';

moment.updateLocale('ar', {
    weekdaysShort: [
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
    ]
});