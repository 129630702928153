
export const getCountryId = () => {
  console.log(window.location.hostname, "hostname >>>>>>>>>>>>")

  if(window.location.hostname == "ksa.cinepolisgulf.com" || window.location.hostname == "ksa-app.cinepolisgulf.com"){
    return 1
  }else if (window.location.hostname == "uae.cinepolisgulf.com" || window.location.hostname == "uae-app.cinepolisgulf.com"){
    return 2
  }else if(window.location.hostname == "bahrain.cinepolisgulf.com" || window.location.hostname == "bahrain-app.cinepolisgulf.com"){
    return 4
  }else if (window.location.hostname == "oman.cinepolisgulf.com" || window.location.hostname == "oman-app.cinepolisgulf.com"){
    return 3
  }else if(window.location.hostname == "uat-cinepolisgcc.enpointe.io"){
    return 3
  }else{
    return 1
  }



}