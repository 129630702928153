import React from 'react';
import { withNamespaces } from 'react-i18next';


function NoInternet({t}) {
    return (
        <div>
            <section class="section-connection-lost">
                <div class="text-center">
                    <h6 class="mb-1">{t('oops')}!</h6>
                    <h6 class="mb-5">{t('connection-lost')}</h6>
                    <p>{t('connect-to-internet')}</p>
                </div>
            </section>
        </div>
    )
}

export default withNamespaces()(NoInternet);