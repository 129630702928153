export const API_VERSION = "v1.0";
export const BASEURL = "https://api.cinepolisgulf.com/api/";
export const ASSETS_BASEURL = "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/";
export const LANDING_PAGE = "https://cinepolisgulf.com/";
export const APP_LANDING_PAGE = "https://app.cinepolisgulf.com/";
export const GOOGLE_MAP_API_KEY = "AIzaSyAG5fxp_0_R9s559gzQRDREtfeAYPiIu4Q";
export const PAYFORT = {
    merchant_identifier: 'XXXXXX',
    access_code: 'XXXXXXX',
    payfort_url: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
    return_url: 'http://localhost:3000/ticket'
}

