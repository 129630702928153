import Api from './Api';

export default {
    GetSHAKey(payload) {
        return Api().post(`shaKey`, payload);
    },
    shaKeyJiZan(payload) {
        return Api().post(`shaKeyJiZan`, payload);
    },

    GetSHAKeyJeddah(payload) {
        return Api().post(`getSHAKeyJeddah`, payload);
    },
    shaKeyAlMassarah(payload) {
        return Api().post(`shaKeyAlMassarah`, payload);
    },
    ResetPassword(payload) {
        return Api().post(`/forgot-password`, payload);
    },
    SendForgotPasswordEmail(payload) {
        return Api().post('/send-forgot-password-email', payload);
    },
    sendGiftMovieTicket(payload){
        console.log("test-3")
        return Api().post('/send-gift-ticket-email', payload);
    },
    getGiftMovieTicket(payload){
        console.log("test-3")
        return Api().get('/get-gift-ticket-email', payload);
    }
}