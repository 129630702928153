import { FETCH_QUICK_BOOK_DATA, FETCH_QUICK_BOOK_ERROR } from "./quickBookTypes";

const initialState = {
    data: [],
    error: null,
}

const quickBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_QUICK_BOOK_DATA:
            return {
                ...state,
                data: action.payload,
                error: null
            }
        //error case
        case FETCH_QUICK_BOOK_ERROR:
            return {
                data: [],
                error: action.payload
            }
        
        default: return state
    }
}

export default quickBookReducer

