import Api from './Api';
import { BASEURL } from "../config/index";
import { createQueryString } from './ApiHelper';
import {getCountryId}  from '../helper/getCountryData'
import axios from 'axios';

const configHeaders = {
    headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }
};

export default {
    GetCities(payload) {
        const country_id = getCountryId()
        const query = createQueryString({
            country_id
        })
        // console.log(country_id, "country_id >>>>>>>>>>>>>>>>")
        return Api().get(`movies/cities/${query}`);
    },
    GetLanguages() {

        const country_id = getCountryId()
        const query = createQueryString({
            country_id
        })
        return Api().get(`movies/languages/${query}`);
    },
    GetCountries(payload) {
        const query = createQueryString(payload)
        return Api().get(`movies/countries/${query}`);
    },
    GetExperiences() {
        return Api().get(`generic/experiences`);
    },
    GetMovieLanguages() {
        const query = createQueryString({
            country_id :  getCountryId()
        })
        return Api().get(`generic/movie-languages/${query}`);
    },
    GetMovieGenres() {
        return Api().get(`generic/movie-genres`);
    },
    GetCountrySpecificData() {
        return Api().get(`generic/country-specific-data`);
    },
    GetCountrySpecificDataJizan() {
        return Api().get(`generic/country-specific-data-jizan`);
    },

    getActiveMailForPg() {
        return Api().get(`generic/get-active-email-pg`);
    },
    GetCountrySpecificDataAlMassarah() {
        return Api().get(`generic/country-specific-data-almassarah`);
    },
    GetSiteData() {
        return Api().get(`generic/site-data`)
    },
    SubmitFeedback(payload) {
        return Api().post(`/movies/submit-feedback`, payload);
    },
    GetFnbMenuInfo(payload) {
        const query = createQueryString(payload)
        return Api().get(`/generic/fnb-menu-info/${query}`);
    },
    GetTermsAndConditions(payload) {
        const query = createQueryString(payload)
        return Api().get(`/generic/terms-and-conditions/${query}`);
    },
    UploadImage(payload) {
        return axios.post(`${BASEURL}/upload/image`, payload, configHeaders)
    },


    GetFaqs() {
        return Api().get(`/movies/faqs`)
    },
}