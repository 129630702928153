import React, { useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import { ASSETS_BASEURL } from "../config/index";
import { Card, Spinner, Dropdown, Accordion } from "react-bootstrap";
import moment from "moment";
import GenericService from "@apiService/GenericService";
import PromotionsService from "@apiService/PromotionsService";
import useIsMobile from "../customHooks/useIsMobile";
import banner from "../assets/pictures/Circle-Mall---Deluxe-Plus---Web-Banner---1300x436.jpg";
import { globalConfigContext } from "@context/GlobalConfigContext";
import useCountries from "../customHooks/useCountries";

import DayPicker from "react-day-picker";

function isEmail(text) {
  let isemail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      text
    );

  return isemail;
}

const AvailOfferUaeMicrosite = ({ t }) => {
  const [dayPickerBool, setDayPickerBool] = useState(false);
  const [accordionArrow, setAccordionArrow] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [firstNameError, setFirstNameEror] = useState(false);
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [mobile, setMobile] = useState();
  const [mobileError, setMobileError] = useState(false);
  const [restaurantError, setRestaurantError] = useState(false);
  const [receiptValue, setReceiptValue] = useState();
  const [receiptValueError, setReceiptValueError] = useState(false);
  const [rawDate, setRawDate] = useState();
  const [dateOfPurchase, setDateOfPurchase] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [tnc1, setTnc1] = useState(true);
  const [tnc1Error, setTnc1Error] = useState(null);
  const [tnc2, setTnc2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { arabic } = useContext(globalConfigContext);
  const [countryCode, setCountryCode] = useState(null);
  const countries = useCountries(countryCode, setCountryCode);

  const [imagePaths, setImagePaths] = useState([]);
  const [imageUploadError, setImageUploadError] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const restaurants = ["Dice Cafe","Gan Bei"];

  const [selectedRestaurant, setSelectedRestaurant] =
    useState("Select restaurant");

  const handleDropdownSelect = (selectedValue) => {
    setRestaurantError(false);
    setSelectedRestaurant(selectedValue);
  };

  const isMobile = useIsMobile();

  const handleImageUpload = (event) => {
    console.log("handleImageUpload triggered", event);

    setImageUploadError(null);
    setIsImageUploading(false);

    setIsImageUploading(false);
    let inputEle = event?.target;

    if (inputEle && inputEle.files && inputEle.files.length > 0) {
      let file = inputEle.files[0];

      const formData = new FormData();
      formData.append("image_type", "receipts");
      formData.append("image", file);

      setIsImageUploading(true);

      GenericService.UploadImage(formData)
        .then(({ data }) => {
          console.log(data);
          if (data.status) {
            let path = data.data.image_path;
            console.log(
              "🚀 ~ file: AvailOfferOman.jsx ~ line 82 ~ .then ~ path",
              path
            );
            setImagePaths((paths) => [...paths, path]);
            setFileError(false);
          } else {
            setImageUploadError(data.message);
          }
        })
        .catch((err) => {
          console.log("Error in uploading image");
          setImageUploadError(err.message);
        })
        .finally(() => {
          setIsImageUploading(false);
        });
    } else {
      setImageUploadError("Please select a file");
    }
  };

  const imageInputRef = useRef();

  const handleAvailOffer = () => {
    console.log(isSubmitting, "isSubmitting");
    if (isSubmitting) {
      return;
    }

    let isError = false;

    setFirstNameEror(null);
    setEmailError(null);
    setMobileError(null);
    // setReceiptValueError(null);
    setDateError(null);
    // setFileError(null);
    setTnc1Error(null);
    setSubmitError(null);
    // setRestaurantError(null);
    setIsSubmitting(false);
    setIsSubmitSuccess(false);

    if (!firstName) {
      isError = true;
      setFirstNameEror(true);
    }

    if (!email || !isEmail(email)) {
      isError = true;
      setEmailError(true);
    }

    if (!mobile || mobile.length < 8) {
      isError = true;
      setMobileError(true);
    }

    // if (!receiptValue) {
    //   isError = true;
    //   setReceiptValueError("Please enter a receipt value");
    // }

    // if (receiptValue && parseInt(receiptValue) < 10) {
    //   isError = true;
    //   setReceiptValueError("Minimum value to enter is 10");
    // }

    // if (!dateOfPurchase) {
    //   isError = true;
    //   setDateError(true);
    // }

    // if (!imagePaths || imagePaths.length === 0) {
    //   isError = true;
    //   setFileError(true);
    // }

    if (!selectedRestaurant) {
      //   setRestaurantError(true);
    }

    if (!tnc1) {
      isError = true;
      setTnc1Error(true);
    }

    if (isError) {
      
      return;
    }

    PromotionsService.SubmitReceiptUaeMicrosite({
      first_name: firstName,
      last_name: lastName,
      country_code: countryCode,
      mobile_number: mobile,
      email,
      images: imagePaths,
      date_of_purchase: dateOfPurchase,
      currency: "AED",
      value_of_receipt: receiptValue,
      has_agreed_for_promotions: tnc2 ? "Y" : "N",
      promotion_name: "Uae Microsite",
      restaurant_name: selectedRestaurant,
    })
      .then(({ data }) => {
        if (data.status) {
          setIsSubmitSuccess(true);
          resetForm();
        } else {
          setSubmitError(data.message);
        }
      })
      .catch((err) => {
        console.log("error while submitting", err);
        setSubmitError(err?.response?.data?.message || err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setFirstNameEror(null);
    setEmail("");
    setEmailError(null);
    setMobile("");
    setMobileError(null);
    setReceiptValue("");
    setReceiptValueError(null);
    setRawDate(null);
    setDateOfPurchase(null);
    setDateError(null);
    setFileError(null);
    setSubmitError(null);
    setTnc1(true);
    setTnc1Error(null);
    setTnc2(null);
    setIsSubmitting(false);
    setImagePaths([]);
    setImageUploadError(null);
    setIsImageUploading(false);
    // setRestaurantError("");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div
        className="container mt-4 mt-md-5"
        onClick={() => {
          setDayPickerBool(false);
        }}
      >
        {isSubmitSuccess && (
          <div class="alert alert-success mb-3" role="alert">
            Thank you for submitting your information.
          </div>
        )}

        <div className="mb-3 w-100">
          <img src={banner} className="w-100" alt="Banner" />
        </div>

        {submitError && (
          <div class="alert alert-danger mb-3" role="alert">
            An error occured: {submitError}
          </div>
        )}
        <section className="row mb-3">
          <article className="col-md-6 mb-3 mb-md-0">
            <input
              type="text"
              className="f-input1 bg-light-black-new px-3 py-2 w-100"
              required
              placeholder={arabic ? "الاسم الأول" : "Enter your first name"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstNameError && (
              <span className="form-error-text d-block text-left">
                Enter your First Name
              </span>
            )}
          </article>
          <article className="col-md-6">
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="f-input1 bg-light-black-new px-3 py-2 w-100"
              placeholder={arabic ? "الاسم الأخير" : "Enter your last name"}
            />
          </article>
        </section>
        <section className="row mb-3">
          <article className="col-md-6 mb-3 mb-md-0">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="f-input1 bg-light-black-new px-3 py-2 w-100"
              placeholder={
                arabic ? "البريد الإلكتروني" : "Enter your email address"
              }
              required
            />
            {emailError && (
              <span className="form-error-text d-block text-left">
                Enter a valid email address
              </span>
            )}
          </article>
          <article className="col-md-6">
            <section className="row">
              <article className="col-4 pr-0">
                <div className="bg-light-black-2 brds-10 mxh-47 w-100">
                  <Dropdown className="w-100 h-100 dropdown-code">
                    <Dropdown.Toggle
                      className="dropdownClassic frxl-16 frl-14 frm-12 frs-10 kumbh fw-reg"
                      id="dropdown-basic"
                    >
                      {countryCode ? `+${countryCode}` : "Country Code"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdownItems">
                      {countries
                        ? countries.map((country, index) => (
                            <Dropdown.Item
                              key={index}
                              className=""
                              onClick={() =>
                                setCountryCode(country.country_code)
                              }
                            >
                              {" "}
                              +{country.country_code}
                            </Dropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </article>
              <article className="col-8">
                <input
                  className="f-input1 bg-light-black-new px-3 py-2 w-100"
                  required
                  type="number"
                  placeholder={arabic ? "رقم الجوال" : "Enter your mobile no."}
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {mobileError && (
                  <span className="form-error-text d-block text-left">
                    Enter a valid mobile number
                  </span>
                )}
              </article>
            </section>
          </article>
        </section>
        <section className="row mb-3">
          {/* <article className="col-md-6 mb-3 mb-md-0">
            <div
              className="date-picker-div w-100"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="text"
                value=""
                className="f-input1 bg-light-black-new px-3 py-2 w-100"
                placeholder={
                  dateOfPurchase
                    ? moment(dateOfPurchase).format("DD-MM-YYYY")
                    : arabic
                    ? "الرجاء اختيار تاريخ الشراء"
                    : "Please select the date of your purchase"
                }
                min="10"
                onClick={() => setDayPickerBool(!dayPickerBool)}
                required
              />

              {dayPickerBool && (
                <DayPicker
                  selectedDays={rawDate}
                  disabledDays={[
                    {
                      after: new Date(2024, 4, 31),
                    },
                  ]}
                  onDayClick={(date, modifiers) => {
                    if (modifiers && modifiers.disabled) return;
                    setRawDate(date);
                    setDateOfPurchase(moment(date).format("YYYY-MM-DD"));
                    setDayPickerBool(false);
                  }}
                />
              )}
            </div>
            {dateError && (
              <span className="form-error-text d-block text-left">
                Select date of purchase
              </span>
            )}
          </article> */}
          {/* <article className="col-md-6">
            <input
              type="number"
              value={receiptValue}
              onChange={(e) => {
                setReceiptValue(e.target.value);
              }}
              className="f-input1 bg-light-black-new px-3 py-2 w-100"
              placeholder={
                arabic
                  ? "أدخل قيمة الإيصال بالدينار البحريني"
                  : "Input the receipt value in AED"
              }
              min="10"
              required
            />
            {receiptValueError && (
              <span className="form-error-text d-block text-left">
                {receiptValueError}
              </span>
            )}
          </article> */}
          <article className="col-md-6">
            <div className="f-input1 bg-light-black-new  mxh-47 w-100">
              <Dropdown className="w-100 h-100 bg-light-black-new kumbh dropdown-code enable-rounded">
                <Dropdown.Toggle
                  className="dropdownClassic frxl-16 frl-14 frm-12 frs-10 fw-reg"
                  id="dropdown-basic"
                >
                  {selectedRestaurant}
                </Dropdown.Toggle>

                <span className="form-error-text d-block text-left">
                  {restaurantError ? "Select your restaurant" : ""}
                </span>
                <Dropdown.Menu className="dropdownItems">
                  {restaurants.map((restaurant, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleDropdownSelect(restaurant)}
                    >
                      {restaurant}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </article>
        </section>

        {/* <section className="row mb-3">
          <article className="col-12">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                ref={imageInputRef}
                onChange={handleImageUpload}
                accept="image/*"
                capture="environment"
                multiple={true}
              />
              {arabic ? (
                <label
                  className="custom-file-label bg-light-black text-white"
                  for="customFile"
                >
                  {!isMobile ? "  حمل صورة لفاتورتك" : "   حمل صورة لفاتورتك  "}
                </label>
              ) : (
                <label
                  className="custom-file-label bg-light-black text-white"
                  for="customFile"
                >
                  {isMobile
                    ? "Upload a photo of your bill"
                    : "Upload a photo of your bill"}
                </label>
              )}
            </div>
            {isImageUploading && (
              <span className="info-text d-block text-left">
                (Image is uploading. Please wait)
              </span>
            )}
            {fileError && (
              <span className="form-error-text d-block text-left">
                Upload the image of your receipt
              </span>
            )}
            {imageUploadError && (
              <span className="form-error-text d-block text-left">
                {imageUploadError}
              </span>
            )}
            {imagePaths?.length > 0 &&
              imagePaths.map((x, xi) => {
                console.log(
                  `${ASSETS_BASEURL}/uploads/images/receipts/${x}`,
                  "urllllllllll"
                );
                return (
                  <>
                    {" "}
                    <figure className="w-100 mb-0 my-3 text-center" key={xi}>
                      <img
                        src={`${ASSETS_BASEURL}uploads/images/receipts/${x}`}
                        className="receipt-page-image"
                        alt=""
                      />
                    </figure>
                  </>
                );
              })}
          </article>
        </section> */}
        <section className="row mb-3 lh-12">
          {arabic ? (
            <article className="col-12 mb-3 mb-md-0">
              <label>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={tnc1}
                  className="ml-2"
                  onChange={(e) => setTnc1(e.target.checked)}
                />
                بتحديد هذا المربع فإنك توافق على شروط وأحكام هذا العرض.
              </label>
              {tnc1Error && (
                <span className="form-error-text d-block text-left">
                  يرجى الموافقة على الشروط والأحكام
                </span>
              )}
            </article>
          ) : (
            <article className="col-12 mb-3 mb-md-0">
              <label>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={tnc1}
                  className="mr-2"
                  onChange={(e) => setTnc1(e.target.checked)}
                />
                By checking this box, you agree to the Terms and Conditions of
                this offer
              </label>
              {tnc1Error && (
                <span className="form-error-text d-block text-left">
                  Please agree to the terms and conditions
                </span>
              )}
            </article>
          )}
          {arabic ? (
            <article className="col-12">
              <label>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className="ml-2"
                  checked={tnc2}
                  onChange={(e) => setTnc2(e.target.checked)}
                />
                بتحديد هذا المربع فإنك توافق على تلقي العروض الترويجية من
                سينيبوليس بالمستقبل.
              </label>
            </article>
          ) : (
            <article className="col-12">
              <label>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className="mr-2"
                  checked={tnc2}
                  onChange={(e) => setTnc2(e.target.checked)}
                />
                By checking this box, you agree to receive future offers,
                promotions and communication from Cinépolis Cinemas
              </label>
            </article>
          )}
        </section>
        <section className="row mb-3">
          <article className="col-12">
            <Accordion className="accordion-1 tnc-accordion mb-3 border-0">
              <Card className="border-0 pb-0">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  className={`${!accordionArrow ? "show" : "hidden"}`}
                  onClick={() =>
                    accordionArrow
                      ? setAccordionArrow(false)
                      : setAccordionArrow(true)
                  }
                >
                  <div className="d-flex justify-content-between align-items-center w-100">
                    {arabic ? "الأحكام والشروط" : "Terms and Conditions"}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {/* {arabic ? (
                      <ul className="">
                        <li>
                          هذا العرض متاح يوم الخميس فقط
                        </li>
                        <li>  يتكون العرض من تذكرة ديلوكس / ديلوكس بلس وكوكاكولا حجم صغير</li>
                        <li>يمكن الاستفادة من هذا العرض عبر الإنترنت فقط. قد ينطبق رسوم عبر الإنترنت</li>
                        <li>هذا العرض يسري فقط على أفلام ثنائية الأبعاد
                        </li>
                        <li>
                          قلا يمكن تغيير المشروب الغازي المتوفر في الحزمة
                        </li>
                        <li>
                          بلا يمكن دمج هذا العرض مع اي عرض اخر أو خصومات اخرى
                        </li>
                        <li>
                          لا يمكن استبدال هذا العرض بقيمة نقدية
                        </li>
                        <li>لالتذاكر التي تم شراؤها لا يمكن استردادها ولا نقلهار</li>
                      </ul>
                    ) : ( */}
                    <ul className="">
                      <li>
                        The voucher code will be applicable on one Deluxe Plus
                        seat only
                      </li>
                      <li>The voucher code may only used one time</li>
                      <li>
                        The voucher code will only be valid on movies in 2D
                      </li>
                      <li>
                        This promotion cannot be combined with other offers or
                        discounts
                      </li>
                      <li>This promotion cannot be exchanged for cash value</li>
                      <li>
                        Tickets once purchased are non-refundable and
                        non-transferable
                      </li>
                      <li>
                        This voucher code can only be redeemed on Cinépolis
                        Cinemas website or app. Online fees may apply
                      </li>
                      <li>
                        This voucher code will be valid up to validity May 31,
                        2024
                      </li>
                    </ul>
                    {/* )} */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </article>
        </section>

        <button
          className="blue-btn px-5 mx-auto mb-3"
          onClick={() => {
            console.log("selectedRestaurant", selectedRestaurant);
            if (selectedRestaurant === "Select a restaurant") {
              setRestaurantError(true);
            } else {
              setRestaurantError(false);
            }
            handleAvailOffer();
          }}
        >
          {isSubmitting ? (
            <Spinner animation="border" variant="light" role="status">
              <span className="sr-only">{t("loading")}</span>̥
            </Spinner>
          ) : arabic ? (
            "يقدم"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
};

export default withNamespaces()(AvailOfferUaeMicrosite);
