const experiences = {
    'premium': "Standard",
    "macro xe": "Macro XE",
    "4de motion": "4D-E Motion",
    "4d e motion": "4D-E Motion",
    "4d e-motion": "4D-E Motion",
    "junior": "Junior",
    "macro": "Macro"
}

export const timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + "h" + rminutes + "m";
}

export const getYoutubeVideoCode = (link) => {
    if(!link) {
        return "";
    }
    
    let code = null;
    link = link.trim();
    if(link.indexOf('youtube.com') !== -1) {
        let res = link.split("=");
        code = res[1].split('&')[0];
    } else {
        let res = link.split('/');
        code = res[3];
    }
    return code;
}

// https://www.youtube.com/watch?v=oImLGeUkWnk

export const getMobileNumber = (number) => {
    if(!number) {
        return [null, null];
    }

    if(number.length === 0) {
        return [number];
    } else {
        let country_code = number.substring(0, 4);
        let mobile_number = number.substring(4);
        if(country_code && country_code.startsWith("+")) {
            country_code = country_code.substring(1, country_code.length)
        }
        return [mobile_number, country_code]
    }
}

export const isImageURLNull = (url) => {
    if(!url || (url.endsWith('null') || url.endsWith('undefined'))) {
        return true;
    }
    return false;
}

export const formatYuppErrors = (errs) => {
    try {
        return errs && errs ? Object.assign({}, ...errs.inner.map(obj => ({ [obj.path]: obj }))) : null;
    } catch(err) {
        console.log(err);
        return {};
    }
}

export function titleCase(word) {
    if(!word) return;
    var sentence = word.toLowerCase().split(" ");
    for(let i = 0; i< sentence.length; i++){
        if(sentence[i]) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
       }
    }
    
    return sentence.join(" ");
}

export const toggleReadMore = (e, description, showMoreText="read more", showLessText="read less") => {
    let descEle = e.target.parentElement.querySelector('.movie-description');
    if(descEle) {
        if(descEle.classList.contains('show-more')) {
            e.target.innerText = showLessText;
            descEle.classList.remove('show-more');
            descEle.classList.add('show-less');
            descEle.innerText = description;
        } else {
            e.target.innerText = showMoreText;
            descEle.classList.remove('show-less');
            descEle.classList.add('show-more');
            descEle.innerText = limitText(description);
        }
    }
}

export const limitText = (sentence) => {
    if(!sentence) return "";

    if(sentence.length > 140) {
        return sentence.substring(0, 133) + "...";
    } else {
        return sentence;
    }
}

export const translateSessionAttribute = (arabic, sessionAttribute) => {
    if(!sessionAttribute) return "";
	if(!arabic) return experienceMapper(sessionAttribute);

	const translations = {
		'premium': 'عادية',
		'macro': 'ماكرو ',
		'macro xe': 'ماكرو ',
		'junior': 'أطفال'
	}

	return translations[sessionAttribute.toLowerCase()] ? translations[sessionAttribute.toLowerCase()] : sessionAttribute
}

export const experienceMapper = (text) => {
    const exp = text ? text.toLowerCase().trim() : "";
    if(experiences[exp]) {
        return experiences[exp]
    }
    return exp;
}