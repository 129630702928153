import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_ERROR,
  FETCH_FNB_ITEMS,
  FETCH_FNB_ITEMS_ERROR,
  SELECT_FNB_ITEM,
  FETCH_MODIFIER_GROUP,
  FETCH_MODIFIER_GROUP_ERROR,
  FETCH_MODIFIFERS,
  FETCH_MODIFIERS_ERROR,
  CLOSE_MODIFIER_POPUP,
  SELECT_MODIFIER,
  FNB_RESERVATION_DONE,
  SET_RECIPE_ITEMS,
  SELECT_MODIFIER_UNDER_RECIPES,
  CLOSE_RECIPE_MODIFIER_POPUP,
  REMOVE_FNB_ITEM,
  SET_TAX_PERCENT_FNB,
  SET_NEW_SELECED_FNB
} from "./foodAndBeveragesTypes";
import { reserveFnbItems } from "@store/booking/bookingActions";
import FnbService from "@apiService/FnbService.js";

const fetchCategoriesSuccess = (categories) => {
  return {
    type: FETCH_CATEGORIES,
    payload: categories,
  };
};

const fetchCategoriesError = (err) => {
  return {
    type: FETCH_CATEGORIES_ERROR,
    payload: err,
  };
};

const fetchFnbItemsSuccess = (fnbItems) => {
  return {
    type: FETCH_FNB_ITEMS,
    payload: fnbItems,
  };
};

const fetchFnbItemsError = (err) => {
  return {
    type: FETCH_FNB_ITEMS_ERROR,
    payload: err,
  };
};

export const onSelectFnb = (fnbId, type, closePopup = false) => {
  return {
    type: SELECT_FNB_ITEM,
    payload: { fnbId, type, closePopup },
  };
};

const fetchModifierGroupSuccess = (data) => {
  return {
    type: FETCH_MODIFIER_GROUP,
    payload: data,
  };
};

const fetchModifierGroupError = (err) => {
  return {
    type: FETCH_MODIFIER_GROUP_ERROR,
    payload: err,
  };
};

const fetchModifierSuccess = (data) => {
  return {
    type: FETCH_MODIFIFERS,
    payload: data,
  };
};

const fetchModifierError = (err) => {
  return {
    type: FETCH_MODIFIERS_ERROR,
    payload: err,
  };
};
export const onCloseModifierPopup = () => ({
  type: CLOSE_MODIFIER_POPUP,
});

export const selectModifier = (modifierId, type) => ({
  type: SELECT_MODIFIER,
  payload: { modifierId, type },
});

export const setFnbReservationDone = () => ({
  type: FNB_RESERVATION_DONE,
});

export const setnewselectedFnbItems = (data) => ({
  type: SET_NEW_SELECED_FNB,
  payload: data
});

export const setRecipeItems = (payload) => ({
  type: SET_RECIPE_ITEMS,
  payload,
});

export const selectModifierUnderRecipes = (payload) => ({
  type: SELECT_MODIFIER_UNDER_RECIPES,
  payload,
});

export const setTaxPercentInFnb = (payload) => ({
  type: SET_TAX_PERCENT_FNB,
  payload,
});

export const onCloseRecipeModifierPopup = () => ({
  type: CLOSE_RECIPE_MODIFIER_POPUP,
});

export const removeFnbItem = (payload) => ({
  type: REMOVE_FNB_ITEM,
  payload,
});

export const fetchAllCategories = (payload) => {
  return function (dispatch) {
    FnbService.GetAllFnbCategories(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.data) {
          dispatch(fetchCategoriesSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchCategoriesError(err.message));
      });
  };
};

export const fetchFnbItems = (payload, setShowLoader) => {
  return function (dispatch) {
    setShowLoader && setShowLoader(true);
    FnbService.GetAllFnbItems(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.data) {
          dispatch(fetchFnbItemsSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchFnbItemsError(err.message));
      })
      .finally(() => setShowLoader && setShowLoader(false));
  };
};

export const fetchFnbModifiersGroup = (payload) => {
  return function (dispatch) {
    FnbService.GetModifierGroup(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.data && data.data.length > 0) {
          dispatch(fetchModifierGroupSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchModifierGroupError(err.message));
      });
  };
};

export const fecthModifiers = (payload, setShowLoader) => {
  return function (dispatch) {
    setShowLoader && setShowLoader(true);
    FnbService.GetAllModifiers(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.data && data.data.length > 0) {
          dispatch(fetchModifierSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchModifierError(err.message));
      })
      .finally(() => setShowLoader && setShowLoader(false));
  };
};

export const fetchRecipeItems = (payload, setShowLoader) => {
  return async function (dispatch) {
    try {
      setShowLoader && setShowLoader(true);
      const response = await FnbService.GetRecipeItems(payload);
      const { data } = response;
      if (data && data.data && data.data.length > 0) {
        dispatch(setRecipeItems(data.data));
      }
    } catch (err) {
      console.log(err);
    }
    setShowLoader && setShowLoader(false);
  };
};

export const removeFnbItemAndUpdateVista = (payload1, payload2) => {
  return async function (dispatch, getState) {
    try {
      dispatch(removeFnbItem(payload1));
      let state = getState();
      await dispatch(
        reserveFnbItems({
          ...payload2,
          items: state.foodAndBeverages.selected_fnb_items,
          fnbPrice: state.foodAndBeverages.fnb_price,
          modifierPrice: state.foodAndBeverages.modifier_price,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
};
