import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'
import { Modal } from "react-bootstrap";
import GenericService from "@apiService/GenericService";
import { CityService, LanguageService } from "@apiService/tokenService";
import { globalConfigContext } from "@context/GlobalConfigContext";
import { withNamespaces } from "react-i18next";
import qs from "qs";
import useIsMobile from "../../customHooks/useIsMobile";

function ChangeCityLanguagePopup({ show, setShow, onClose, showBackdrop = true, t }) {
  const { arabic, refreshPageContent, country_data } = useContext(
    globalConfigContext
  );

  

  const [originalLanguage, setOriginalLanguage] = useState(null);
  const [originalLanguageId, setOriginalLanguageId] = useState(null);
  const [originalCity, setOriginalCity] = useState(null);
  const [cities, setCities] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [cityHover, setCityHover] = useState(false)
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const handleClose = () => {
    if (originalLanguage && originalCity) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      const city_data = CityService.getCity();
      const language = LanguageService.getLanguage();

      if (city_data) {
        setCityId(city_data.cityId);
        setCityName(city_data.cityName);
      }

      if (language) {
        setLanguageId(language);
      }
    }
  }, [show]);

  const onSelectCity = (eventKey) => {
    setCityHover(false)
    setCityId(eventKey);
    let city_name = cities.find((city) => city.city_id == eventKey).city_name;
    setCityName(city_name);
  };

  const onSelectLanguage = (eventKey) => {
    setLanguageId(eventKey);
  };

 

  // useEffect(() => {
  //   dispatch(showInput(true));
  // }, [selectCityState])

  const onConfirm = (e) => {

    if(cityHover == true){
      e.preventDefault()
      setShow(false)
    }else{
    setShowError(false);
    if (!cityId || !cityName || (isMobile && !languageId)) {
      setShowError(true);
      return;
    }

    CityService.saveCity({
      cityId,
      cityName,
    });

    if (isMobile) {
      LanguageService.saveLanguage(languageId);
    }

    console.log("test 1");
    const searchparams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!isMobile && !languageId) {
      LanguageService.saveLanguage("en");
      setLanguageId("en");
    }

    if (
      ((isMobile && languageId === originalLanguageId) || !isMobile) &&
      cityName === originalCity
    ) {
      onClose();
      return;
    }

    if (
      ((isMobile && languageId === originalLanguageId) || !isMobile) &&
      cityName !== originalCity
    ) {
      onClose();
      refreshPageContent();
      return;
    } else {
      if (searchparams.city_id || searchparams.lang) {
        delete searchparams.city_id;
        delete searchparams.lang;
        delete searchparams.hide_popup;
        window.location.search = qs.stringify(searchparams, {
          addQueryPrefix: true,
        });
      } else {
        window.location.reload();
      }
    }

  }
    // document.location.href="/";

    // history.go(-1);
    // history.go(0);
  };


  useEffect(() => {
    const language = LanguageService.getLanguage();
    setOriginalLanguageId(language);
    if (language && languages) {
      setLanguageId(language);
      let languageObj = languages.find(
        (lang) => lang.site_lang_short_code === language
      );
      if (languageObj) {
        setOriginalLanguage(languageObj.site_lang);
      } else {
        setOriginalLanguage(null);
      }
    }
  }, [languages, show]);

  useEffect(() => {
    const city_data = CityService.getCity();

    if (city_data) {
      const { cityId, cityName } = city_data;
      setCityId(cityId);
      setCityName(cityName);
      setOriginalCity(cityName);
    }
  }, [cities, show]);

  useEffect(() => {
    GenericService.GetCities().then((response) => {
      if (response && response.data) {
        setCities(response.data.data);
        console.log(response.data.data, "lllllllllllllllllllllllllllllllllllllllllllllllllllllllllll");
      }
    });

    GenericService.GetLanguages().then((response) => {
      if (response && response.data) {
        setLanguages(response.data.data);
      }
    });
  }, []);

  return (
    <Modal
      // show={show}
      show={false}
      onHide={handleClose}
      centered
      backdrop={showBackdrop}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-3 large-zindex"
    >
      <Modal.Header
        closeButton={originalLanguage && originalCity}
        className="align-items-start pt-3 pb-2 pt-md-4 pb-md-3 px-3 pl-md-3"
      >
        {
          country_data && country_data.country_short_code === "BH" ? (
            <p className="fw-bold frxl-28 frl-24 frm-20 frs-16 px-md-3">
          {isMobile
            ? originalLanguage && originalCity
              ? arabic
                ? t("change-city-lang")
                : t("change") + t("city-n-lang")
              : arabic
                ? t("select-city-lang")
                : t("choose") + t("city-n-lang")
            : originalLanguage && originalCity
              ? arabic
                ? t("change") + t("cinema")
                : t("change") + t("cinema")
              : arabic
                ? t("choose") + t("cinema")
                : t("choose") + t("cinema")}
        </p>
          ):
          (
            <p className="fw-bold frxl-28 frl-24 frm-20 frs-16 px-md-3">
          {isMobile
            ? originalLanguage && originalCity
              ? arabic
                ? t("change-city-lang")
                : t("change") + t("city-n-lang")
              : arabic
                ? t("select-city-lang")
                : t("choose") + t("city-n-lang")
            : originalLanguage && originalCity
              ? arabic
                ? t("change") + t("city")
                : t("change") + t("city")
              : arabic
                ? t("choose") + t("city")
                : t("choose") + t("city")}
        </p>
          )
        }
      </Modal.Header>
      <Modal.Footer className="flex-column align-items-start px-md-3">
        <p className="frl-20 frm-18 frs-14 fw-bold dls pt-3 pb-2 px-2 px-md-3 d-md-none">
          {originalLanguage && originalCity
            ? arabic
              ? t("choose-city")
              : t("change") + t("city")
            : arabic
              ? t("choose-city")
              : t("choose") + t("city")}
        </p>
        <div className="w-100 h-100 d-flex flex-wrap align-items-center bb-1">
          {cities &&
            cities.length > 0 &&
            cities.map((city, index) => (
              <div key={index} className="">
                <span
                  className={`modal-btn px-4 mx-2 mx-md-3 my-2 frxl-16 frl-14 frs-12 ${city.city_id === cityId ? "active" : ""
                    }`}
                  onClick={() => onSelectCity(city.city_id)}
                >
                  {arabic ? city.city_name_ar : city.city_name}
                </span>
              </div>
            ))}
          <div className="">
            {/* <span
              className={`modal-btn px-4 mx-2 mx-md-3 my-2 frxl-16 frl-14 frs-12 ${cityHover == true ? "active": ""}`}
              onClick={() => onSelectAllCity()}
            >
              {arabic ? city.city_name_ar : city.city_name}
              All Cities
            </span> */}
          </div>
        </div>
        {isMobile && (
          <>
            <p className="frl-20 frm-18 frs-14 fw-bold dls pt-3 px-2 px-md-3">
              {originalLanguage && originalCity
                ? arabic
                  ? t("choose-lang")
                  : t("change") + t("lang")
                : arabic
                  ? t("choose-lang")
                  : t("choose") + t("lang")}
            </p>
            <div className="w-100 h-100 d-flex flex-wrap align-items-center bb-1">
              {languages &&
                languages.length > 0 &&
                languages.map((language, index) => (
                  <div key={index} className="">
                    <div
                      className={`modal-btn px-4 mx-2 mx-md-3 my-2 frxl-16 frl-14 frs-12 ${language.site_lang_short_code === languageId
                        ? "active"
                        : ""
                        }`}
                      onClick={() =>
                        onSelectLanguage(language.site_lang_short_code)
                      }
                    >
                      {language.site_lang}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
        {showError && (
          <p className="form-error-text d-block text-align-center my-2 mx-auto">
            {t("city-n-lang-must")}
          </p>
        )}
        <div className="mx-auto my-3">
          <button
            className="btn btn-purple px-5 fs-14"
            href=""
            onClick={onConfirm}
          >
            {t("common.CONFIRM")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default withNamespaces()(ChangeCityLanguagePopup);
