import { PROMOTIONS_LOADER, FETCH_PROMOTION_SUCCESS, PROMOTIONS_ERROR, SET_ONLY_BANK_OFFERS_PROMOTIONS } from "./promotionsTypes";

const initialState = {
    promotions_loading: false,
    offers: [],
    only_bank_offer_promotions: [],
    promotions_error: null
}

const promotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROMOTIONS_LOADER:
            return {
                ...state,
                promotions_loading: true,
            }
        case FETCH_PROMOTION_SUCCESS:
            return {
                ...state,
                promotions_loading: false,
                offers: action.payload,
            }
        case PROMOTIONS_ERROR:
            return {
                ...state,
                promotions_loading: false,
                offers: [],
                promotions_error: action.payload
            }
        case SET_ONLY_BANK_OFFERS_PROMOTIONS: {
            return {
                ...state,
                only_bank_offer_promotions: action.payload
            }
        }
        default: return state
    }
}

export default promotionsReducer

