import { isInStandaloneMode } from '@helper/utils';
import { PwaService } from '@apiService/tokenService';

export function getGAId() {
    let isPwa = isInStandaloneMode() || PwaService.getIsPwa() === "true";
    
    let id;
    let isKsa = window.location.hostname.includes('ksa');
    let isUae = window.location.hostname.includes('uae');
    let isOman = window.location.hostname.includes('oman');
    let isBahrain = window.location.hostname.includes('bahrain');

    if(isKsa) {
        // id = isPwa ? 'G-009D3C6B1H' : 'G-8K9NH7ZZDK';
      id = isPwa ? 'UA-184008312-4' : 'UA-184008312-3';
    } else if(isUae) {
      // id = isPwa ? 'G-JZQK6G528M' : 'G-KMHKX98M5B';
      id = isPwa ? 'UA-184008312-5' : 'UA-184008312-6';
    } else if(isOman) {
      // id = isPwa ? 'G-J7YNH652VJ' : 'G-NWHXDY4C3Y';
      id = isPwa ? 'UA-184008312-7' : 'UA-184008312-8';
    } else if(isBahrain) {
      // id = isPwa ? 'G-8HL56Y6K1G' : 'G-L1T7827MYM';
      id = isPwa ? 'UA-184008312-9' : 'UA-184008312-10';
    }

    return id;
}