import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    LOGIN_USER_ERROR,
    LOGIN_USER_LOADING,
    LOGIN_USER_SUCCESS,
    SIGNUP_USER_ERROR,
    SIGNUP_USER_LOADING,
    SIGNUP_USER_SUCCESS,
    LOGOUT,
    ADD_USER,
    GET_USER_DETAILS,
    CURRENT_USER_VERIFIED,
    SET_TEMP_USER,
    SET_GUEST_USER,
    SET_GUEST_ERROR,
    SET_USER_BOOKINGS,
    REMOVE_MIGRATED_USER_DATA,
    REMOVE_GUEST_USER,
    SIGNUP_USER_SUCCESS_NEW
} from './userTypes';
import UserService from '@apiService/UserService.js';
import { TokenService } from '@apiService/tokenService';
import QRCode from 'qrcode';

var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 1,
    width: '150',
    color: {
        dark: "#000000",
        light: "#FFFFFF"
    }
}

//Loading action to set flag true/false
export const fetchUser = () => {
    return {
        type: FETCH_USER,
    };
};

//action called after user success response
const fetchUserSuccess = (users) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: users,
    };
};

//action called when there is an error response
const fetchUserError = (err) => {
    return {
        type: FETCH_USER_ERROR,
        payload: err,
    };
};

const setLoginLoading = () => ({
    type: LOGIN_USER_LOADING,
});

export const setLoginSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload,
});

export const setLoginError = (payload) => ({
    type: LOGIN_USER_ERROR,
    payload,
});

const setSignupLoading = () => ({
    type: SIGNUP_USER_LOADING,
});

export const setSignupSuccess = (payload) => ({
    type: SIGNUP_USER_SUCCESS,
    payload,
});

export const setSignupSuccessNew = (payload) => ({
    type: SIGNUP_USER_SUCCESS_NEW,
    payload,
});

export const setSignupError = (payload) => ({
    type: SIGNUP_USER_ERROR,
    payload,
});

// const setCurrentUserVerified = () => ({
//     type: CURRENT_USER_VERIFIED,
// });

const setTempUser = (payload) => ({
    type: SET_TEMP_USER,
    payload
})

export const userLogout = () => {
    TokenService.removeToken();
    return {
        type: LOGOUT,
    };
};

//action called when single user is added
const addSingleUser = (user) => {
    return {
        type: ADD_USER,
        payload: user,
    };
};

const getUserDetails = (userdata) => {
    return {
        type: GET_USER_DETAILS,
        payload: userdata,
    };
};

const setGuestUser = (payload) => ({
    type: SET_GUEST_USER,
    payload
});

const setGuestError = (payload) => ({
    type: SET_GUEST_ERROR,
    payload
});

export const removeGuestUsr = () => ({
    type: REMOVE_GUEST_USER
})

const setUserBookings = (payload) => ({
    type: SET_USER_BOOKINGS,
    payload
})

export const clearMigratedUserData = () => ({
    type: REMOVE_MIGRATED_USER_DATA
})

export const userLogin = (payload, callback) => {
    return function (dispatch) {
        dispatch(setLoginLoading());
        UserService.Login(payload)
            .then((response) => {
                if (response && response.status === 200) {
                    if (response.data.data.user.is_verified === 'Y') {
                        if (response.data.data.token) {
                            TokenService.saveToken(response.data.data.token);
                            localStorage.setItem('usertoken', response.data.data.token)
                        }
                        dispatch(setLoginSuccess(response.data.data.user));
                        localStorage.setItem('userdetails', JSON.stringify(response.data.data.user))
                        // dispatch(fetchCurrentUserDetails())
                        const user = response.data.data.user
                        if (!(user.is_migrated_user === 'Y' && user.has_set_new_password === 'N')) {
                            callback();
                        }
                    } else {
                        dispatch(setTempUser(response.data.data.user));
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    dispatch(setLoginError(err.response.data));
                } else {
                    dispatch(setLoginError(err.message));
                }
            });
    };
};

export const userSignup = (payload) => {
    return function (dispatch) {

        let zz = payload?.setLoyaltyActiveModal;
        delete payload.setLoyaltyActiveModal;


        dispatch(setSignupLoading());
        UserService.Signup(payload)
            .then((response) => {
                if (response && response.status === 200) {
                    if (response.data.data.token) {
                        TokenService.saveToken(response.data.data.token);
                        // localStorage.setItem('usertoken', response.data.data.token)
                    }
                    dispatch(setSignupSuccessNew(response.data.data.user));
                    // localStorage.setItem('userdetails', JSON.stringify(response.data.data.user))
                    zz(true);
                }
            })
            .catch((err) => {
                dispatch(setSignupError(err.response.data));
            });
    };
};

export const userSignupExisting = (payload) => {
    return function (dispatch) {
        let zz = payload?.setLoyaltyActiveModal;
        delete payload.setLoyaltyActiveModal;
        dispatch(setSignupLoading());
        UserService.LoyaltyRegisterExistingUser(payload)
            .then((response) => {
                if (response && response.status === 200) {
                    if (response.data.data.token) {
                        TokenService.saveToken(response.data.data.token);
                        localStorage.setItem('usertoken', response.data.data.token)
                    }
                    dispatch(setSignupSuccess(response.data.data.user));
                    localStorage.setItem('userdetails', JSON.stringify(response.data.data.user))
                    zz(true)
                }
            })
            .catch((err) => {
                dispatch(setSignupError(err.response.data));
            });
    };
};

// export const userSignupExistingLogin = (payload) => {
//     return function (dispatch) {
//         let zz = payload?.setLoyaltyActiveModal;
//         delete payload.setLoyaltyActiveModal;
//         dispatch(setLoginLoading());
//         UserService.LoyaltyRegisterExistingUserLogin(payload)
//             .then((response) => {
//                 if (response && response.status === 200) {
//                      console.log(response.data.data ,"response.data.data>>")
//                     if (response.data.token) {
//                         TokenService.saveToken(response.data.data.token);
//                         localStorage.setItem('usertoken', response.data.data.token)
//                     }
//                     dispatch(setLoginSuccess(response.data.data.user));
//                     localStorage.setItem('userdetails', JSON.stringify(response.data.data.user))
//                     zz(true)
//                 }
//             })
//             .catch((err) => {
//                 dispatch(setLoginError(err.response.data));
//             });
//     };
// };

export const socialLogin = (payload, callback) => {
    return async function (dispatch) {
        dispatch(setLoginLoading());
        dispatch(setSignupLoading());
        try {
            const response = await UserService.SocialLogin(payload);
            if (response && response.status === 200) {
                TokenService.saveToken(response.data.data.token);
                dispatch(setLoginSuccess(response.data.data.user));
                callback();
            }
        } catch (err) {
            if (err.response) {
                dispatch(setLoginError(err.response.data));
                dispatch(setSignupError(err.response.data));
            } else {
                dispatch(setLoginError(err.message));
                dispatch(setSignupError(err.message));
            }
        }
    }
}


export const saveGuest = (payload) => {
    return async function (dispatch) {
        try {
            const response = await UserService.SaveGuest(payload);
            if (response && response.status === 200) {
                dispatch(setGuestUser(response.data.data))
            }
        } catch (err) {
            dispatch(setGuestError(err.response ? err.response.data.message : err.message));
        }
    }
}

export const verifyUser = (payload, type = 'login', callback, redirectToResetPassword) => {
    return function (dispatch) {
        UserService.VerifyUser(payload)
            .then((response) => {
                if (response && response.status === 200) {

                    TokenService.saveToken(response.data.data.token);
                    localStorage.setItem('usertoken', response.data.data.token)

                    if (response.data.data.redirect_to_reset_password === true) {
                        redirectToResetPassword(response.data.data.resetData);
                        dispatch(clearMigratedUserData());
                    } else {
                        dispatch(setLoginSuccess(response.data.data.user));
                        localStorage.setItem('userdetails', JSON.stringify(response.data.data.user))
                    }
                    callback();
                }
            })
            .catch((err) => {
                if (type === 'login') {
                    dispatch(setLoginError(err.response.data));
                } else if (type === 'signup') {
                    dispatch(setSignupError(err.response.data));
                }
            });
    };
};

// async function to fetch user using axios
export const fetchCurrentUserDetails = (setloyaltydataa, setloyaltyloader) => {
    return async function (dispatch) {
        dispatch(fetchUser());
        const token = TokenService.getToken();
        if (token) {
            try {
                const response = await UserService.GetUserDetails();
                if (response && response.data.data) {
                    dispatch(fetchUserSuccess(response.data.data))
                    setloyaltydataa(response.data.data)
                    setloyaltyloader(false)
                }
            } catch (err) {
                console.log(err);
                dispatch(fetchUserError(err.message));
                setloyaltydataa(null);
                setloyaltyloader(false)
                throw err;
            }
        } else {
            setloyaltydataa(null)
            setloyaltyloader(false)
            throw new Error("not logged in");
        }
    };
};

export const fetchUserBookings = () => {
    return async function (dispatch) {
        try {
            const response = await UserService.GetUserBookings();
            if (response && response.status === 200) {
                let bookings = response.data.data;
                for (let i = 0; i < bookings.length; i++) {
                    bookings[i].qrcode = await QRCode.toDataURL(bookings[i].bookingId, opts);
                }
                dispatch(setUserBookings(bookings));
            }
        } catch (err) {
            console.log(err);
        }
    }
}

//async function to post username
export const addUser = (name) => {
    return function (dispatch) {
        UserService.AddUser({ name: name })
            .then((response) => {
                const { data } = response;
                //dispatch add response
                dispatch(addSingleUser(data));
            })
            .catch((err) => {
                //dispatch error response
                dispatch(fetchUserError(err.message));
            });
    };
};

export const fetchUserDetails = (user_id) => {
    return function (dispatch) {
        UserService.GetSingleUserDetails({ user_id: user_id })
            .then((response) => {
                const { data } = response;
                //dispatch add response
                dispatch(getUserDetails(data));
            })
            .catch((err) => {
                //dispatch error response
                dispatch(fetchUserError(err.message));
            });
    };
};

export const getLoyaltyTransaction = (data) => {
    return function (dispatch) {
        UserService.getLoyaltyTransections(data)
            .then((response) => {
                console.log(response, "this is getloyaltytranaction")
                // const { data } = response;
                //dispatch add response
                // dispatch(getUserDetails(data));
            })
            .catch((err) => {
                console.log(err, "this is getloyaltytranaction")
                //dispatch error response
                // dispatch(fetchUserError(err.message));
            });
    };
};

export const updateProfile = (payload) => {
    return async function (dispatch) {
        try {
            const response = await UserService.UpdateUserProfile(payload);
            if (response && response.status === 200) {
                dispatch(fetchCurrentUserDetails());
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}
