import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

function useGoogleAnalytics() {
    useEffect(() => {
        // console.log("pageview captured");
        ReactGA.pageview(window.location.pathname);
    }, []);
}

export default useGoogleAnalytics