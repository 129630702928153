import { ADD_ALL_CINEMAS } from './cinemasTypes';

let initialState = {
    allCinemas: null,
};

const cinemasReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_CINEMAS:
            return {
                ...state,
                allCinemas: action.payload,
            };
        default:
            return state;
    }
};

export default cinemasReducer;
