import React, { useState, useEffect, useContext } from "react";
import useIsOnline from "./customHooks/useIsOnline";
import { Provider } from "react-redux";
import NoInternet from "@components/NoInternet";
import CountrySelectorContainer from "@components/CountrySelectorContainer";
import { withNamespaces } from "react-i18next";
import Axios from "axios";
import ErrorPopup from "@components/partials/ErrorPopup";
import ReactPixel from 'react-facebook-pixel';
import moment from "moment";
import "moment/locale/ar";
import "@helper/update-ar-locale";
//import store
import store from "./store/index";
// import router
import Router from "./Router";
import ReactGA from "react-ga";
import { getGAId } from "@helper/googleAnalytics";
//import i18n
import "./plugins/i18n";
//import compoents
import Header from "@components/partials/Header";
import Footer from "@components/partials/Footer";
import { globalConfigContext } from "@context/GlobalConfigContext";
//CSS Imports
import '@assets/css/common.css';
import '@assets/css/style.css';
import '@assets/css/new-style.css';
import '@assets/css/style-two.css';
import "react-day-picker/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@assets/css/slick.css";
import "@assets/css/responsive.css";
import "@assets/css/ClubCinepolis.css";
import "@assets/css/ClubCinepolisArabic.css";
import "react-modal-video/css/modal-video.min.css";
import MenuContainer from "@components/partials/MenuContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LanguageService } from "@apiService/tokenService";
const language = LanguageService.getLanguage();

function App({ t }) {
  const { country_data } = useContext(globalConfigContext);
  const isOnline = useIsOnline();
  const pathname = window.location.pathname;
  //   console.log(window.location.pathname,"Hapy"); //yields: "/js" (where snippets run)
  // console.log(window.location.href);     //yields: "https://stacksnippets.net/js"
  // const [showOperatingDaysMsgPopup, setShowOperatingDaysMsgPopup] = useState(true);

  const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  useEffect(() => {
    if (language === "ar") {
      moment.locale("ar");
    } else {
      moment.locale("en");
    }

    let ID = getGAId();
    ReactGA.initialize(ID);
    ReactGA.pageview(window.location.pathname);


    const event_id = Math.floor(
      100000 + Math.random() * 600
    );
    const event_time = new Date()

    Axios.get('https://ipapi.co/json/').then((res) => {

      // console.log(res, "res >>>>>>>>>>")

      const dataPixelBody = {
        data: [
          {
            event_name: "PageView",
            event_time: event_time,
            event_id: `event.id.${event_id}`,
            event_source_url: window.location.href,
            user_data: {
              client_ip_address: res.data.ip,
              client_user_agent: getBrowser(),
              fbc: null,
              fbp: getFbClientId()
            }
          }
        ]
      }


      // console.log(dataPixelBody, "dataPixelBody")

      Axios.post(
        'https://graph.facebook.com/v15.0/7030488373691531/events?access_token=EAALDDyTQBCIBAPBBRvOabHakVAl9fzs3QWYZChY4FlTOImSeRJi0VkkJJyZCVzYAA9QZA4AXTpWNN3hQohqZBmBCUFXHA7krVBqViD9c5gMZBmFVK8L8zbOhANMMHbuvBPpXJBUBQ8AR7DTbdd6FrccsKRZAhhmZAZA8J4B6jZCBbC2Ln5d4etyVm',
        dataPixelBody
      ).then((res) => {
        // console.log(res, "resp Page View >>>>>>>>>>>>>>> >>>>>>>> >>>")
      })

    })



  }, []);

  const getBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName
  }
  function getFbClientId() {
    let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
    console.log(result, "result >>>>>>>>>>>>>>>>> >>>>>>> >>>")
    if (!(result && result[1])) {
      return null;
    }
    return result[1];
  }

  if (
    window.location.hostname === "cinepolis-app-dev.binarynumbers.io" ||
    window.location.hostname === "uat-cinepolis.cinepolisgulf.com" ||
    window.location.hostname === "app.cinepolisgulf.com" ||
    window.location.hostname === "www.cinepolisgulf.com" ||
    window.location.hostname === "cinepolisgulf.com"
  ) {
    return <CountrySelectorContainer />;
  }

  return isOnline ? (
    <Provider store={store}>

      {(pathname !== '/payment/credimax' || pathname !== '/paymentcredimaxamwaj') && <Header />}
      {/* { &&<Header />} */}
      {/* {location.pathname !== '/experience-new' && <Header />} */}

      <div className="App mobile-margin-bottom">
        <Router />
      </div>
      {(pathname !== "/select-country" || pathname !== "/payment/credimax" || pathname !== '/paymentcredimaxamwaj') && <Footer />}
      {/* <Footer /> */}
      <MenuContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <ErrorPopup 
					show={showOperatingDaysMsgPopup && country_data && country_data.country_short_code === 'OM'} 
					showEmoji={false} 
					action={() => setShowOperatingDaysMsgPopup(false)} 
					title={t('common.Notice')} 
					subtitle={t('seatlayout_page.covid-disclaimer-popup.oman-junior-operating-days-msg')} buttonText={t('common.OK')} 
				/> */}
    </Provider>
  ) : (
    <NoInternet />
  );
}

export default withNamespaces()(App);
