import {
    FETCH_COMING_SOON_MOVIES, FETCH_COMING_SOON_ERROR, FETCH_NOW_PLAYING_MOVIES, FETCH_NOW_PLAYING_ERROR, FETCH_BANNERS, FETCH_BANNERS_ERROR,
    FETCH_MOVIE_DETAILS, FETCH_MOVIE_DETAILS_ERROR, FETCH_SHOW_TIMES, FETCH_SHOW_TIMES_ERROR, FETCH_CURRENT_MOVIE_SHOW_DATES,
    SET_MOVIE_DETAILS_LOADER,
    SET_SHOW_TIMES_LOADER,
    CLEAR_MOVIE_DETAILS_ERROR,
    SET_MOVIE_LANGUAGES,
    SET_MOVIE_GENRES,
    FETCH_MEXICAN_FF_MOVIES_SUCCESS,
    FETCH_MEXICAN_FF_MOVIES_ERROR,
    show,
    SHOW_INPUT
} from "./moviesTypes";

const initialState = {
    coming_soon_movies: [],
    comming_soon_error: null,
    all_coming_soon: [],
    now_playing_movies: [],
    now_playing_error: null,
    banners: [],
    banners_error: null,
    movie_details: [],
    movie_details_error: null,
    show_times: [],
    show_times_error: null,
    current_movie_show_dates: null,
    show_times_loader: false,
    movie_details_loader: false,
    movie_languages: null,
    movie_genres: null,
    mexican_movies: [],
    show_input: false,
    mexican_movies_err: null
}

const moviesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMING_SOON_MOVIES:
            return {
                ...state,
                coming_soon_movies: action.payload.withSessions,
                all_coming_soon: action.payload.all,
                comming_soon_error: null
            }
        case FETCH_COMING_SOON_ERROR:
            return {
                ...state,
                coming_soon_movies: [],
                comming_soon_error: action.payload
            }
        case FETCH_NOW_PLAYING_MOVIES:
            return {
                ...state,
                now_playing_movies: action.payload,
                now_playing_error: null
            }
        case FETCH_NOW_PLAYING_ERROR:
            return {
                ...state,
                now_playing_movies: [],
                now_playing_error: action.payload
            }
        case FETCH_MEXICAN_FF_MOVIES_SUCCESS:
            return {
                ...state,
                mexican_movies: action.payload,
                mexican_movies_err: null
            }
        case FETCH_MEXICAN_FF_MOVIES_ERROR:
            return {
                ...state,
                mexican_movies: [],
                mexican_movies_err: action.payload
            }
        case SHOW_INPUT :
            return {
                ...state,
                show_input: action.payload
            }
        case FETCH_BANNERS:
            return {
                ...state,
                banners: action.payload
            }
        case FETCH_BANNERS_ERROR:
            return {
                ...state,
                banners: [],
                banners_error: action.payload
            }
        case FETCH_MOVIE_DETAILS:
            return {
                ...state,
                movie_details: action.payload,
                movie_details_loader: false
            }
        case FETCH_MOVIE_DETAILS_ERROR:
            return {
                ...state,
                movie_details_error: action.payload,
                movie_details_loader: false
            }
        case FETCH_SHOW_TIMES:
            return {
                ...state,
                show_times: action.payload,
                show_times_loader: false
            }
        case FETCH_SHOW_TIMES_ERROR:
            return {
                ...state,
                show_times_error: action.payload,
                show_times_loader: false
            }
        case FETCH_CURRENT_MOVIE_SHOW_DATES:
            return {
                ...state,
                current_movie_show_dates: action.payload
            }
        case SET_SHOW_TIMES_LOADER:
            return {
                ...state,
                show_times_loader: action.payload
            }
        case SET_MOVIE_DETAILS_LOADER:
            return {
                ...state,
                movie_details_loader: action.payload
            }
        case CLEAR_MOVIE_DETAILS_ERROR:
            return {
                ...state,
                movie_details_error: null
            }
        case SET_MOVIE_LANGUAGES:
            return {
                ...state,
                movie_languages: action.payload
            }
        case SET_MOVIE_GENRES:
            return {
                ...state,
                movie_genres: action.payload
            }
        default: return state
    }
}

export default moviesReducer

