import Api from "./Api";

export default {
  GetSeatLayout(payload) {
    return Api().get(
      `booking/seat-layout-test/${payload.session_id}/${payload.cinema_id}`
    );
  },
  SelectSeats(payload) {
    return Api().post(`booking/select-seats`, payload);
  },
  BookSeats(payload) {
    return Api().post(`booking/book-seats`, payload);
  },
  GetBookingDetails(payload) {
    return Api().get(`booking/booking-details/${payload.reservation_id}`);
  },
  GetTicketPrice(payload, all = true) {
    return Api().get(
      `booking/ticket-price/${payload.session_id}/${payload.cinema_id}/${all ? "?all=Y" : ""
      }`
    );
  },
  CancelTransaction(payload) {
    return Api().get(`booking/cancel-transaction/${payload.reservation_id}`);
  },
  SelectFnbItems(payload) {
    return Api().post(`booking/select-fnb`, payload);
  },
  ValidateVoucher(payload) {
    return Api().post(`booking/validate-voucher`, payload);
  },
  ResetOrderExpiry(payload) {
    return Api().post(`booking/reset-order-expiry/${payload.reservation_id}`);
  },
  UpdateReservationCheckoutTable(payload) {
    return Api().post(`booking/update-resrvation-checkout`, payload);
  },

  AddPbEvent(payload) {
    console.log(payload, "Envent >>>>>>>>>>>>")
    return Api().post(`booking/add-purchase-facebook-evnet`, payload);
  },

  getDuotixPassOrder(payload) {
    return Api().post(`booking/getDuotixPassOrder`, payload);
  },

  getVistaBookingNoPGDuotixPassOrder(payload) {
    return Api().post(`booking/vista-booking-without-pg`, payload);
  },


  tapPayCheckout(payload) {
    console.log("tap pay payload >>>>>>>", payload)
    return Api().post(`booking/tapPayCheckout`, payload);
  },

  tapPayCheckoutWithBenefit(payload) {
    console.log("tap pay payload >>>>>>>", payload)
    return Api().post(`booking/tapPayCheckoutBenefit`, payload);
  },



  tapPayCheckoutAmwaj(payload) {
    console.log("tap pay payload >>>>>>>", payload)
    return Api().post(`booking/tapPayCheckout-amwaj`, payload);
  },

  tapPayCheckoutWithBenefitAmwaj(payload) {
    console.log("tap pay payload >>>>>>>", payload)
    return Api().post(`booking/tapPayCheckoutBenefit-amwaj`, payload);
  },

  DownloadTicket(payload) {
    // console.log(payload,"DownloadTicket")
    return Api().post(`booking/final-ticket-download/${payload.transaction_id}`, payload);
  },

  GetOrderForConcession(payload) {
    return Api().post(`booking/get-order-for-concession/`, payload);
  },

  addLoyaltyPointsToPayment(payload) {
    // return Api().post(`http://localhost:8001/api/booking/addLoyaltyPointsToPayment`, payload);
    return Api().post(`booking/addLoyaltyPointsToPayment`, payload);
  },
  removeLoyaltyPointsFromPayment(payload) {
    // return Api().post(`http://localhost:8001/api/booking/removeLoyaltyPointsFromPayment`, payload);
    return Api().post(`booking/removeLoyaltyPointsFromPayment`, payload);
  },
};
