import React, { useEffect, useState, useContext } from "react";
import { globalConfigContext } from "@context/GlobalConfigContext";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import GenericService from "@apiService/GenericService";

import { LanguageService, PwaService } from "@apiService/tokenService";
import useGoogleAnalytics from "../customHooks/useGoogleAnalytics";
import { CountryService } from "@apiService/tokenService";
import qs from "qs";
import CustomerSpinner from "@components/partials/Spinner";
import { Helmet } from "react-helmet";
import "remixicon/fonts/remixicon.css";
import useUserDetails from "../customHooks/useUserDetails";

import flagBahrain from "../assets/pictures/flag-bahrain.png";
import flagKsa from "../assets/pictures/flag-saudi-arabia.png";
import flagOman from "../assets/pictures/flag-oman.png";
import flagUae from "../assets/pictures/flag-uae.png";
import cinepolisLogo from "../assets/pictures/svgs/logo-cinepolis.svg";

function getTargetUrl(countryObj, isPwa) {
  let hostname = window.location.hostname;

  if (hostname === "cinepolis-app-dev.binarynumbers.io") {
    return isPwa ? countryObj.website_url : countryObj.website_url;
  } else if (hostname === "uat-cinepolis.cinepolisgulf.com") {
    return isPwa ? countryObj.uat_website_url : countryObj.uat_website_url;
  } else if (
    hostname === "app.cinepolisgulf.com" ||
    hostname === "cinepolisgulf.com" ||
    hostname === "www.cinepolisgulf.com"
  ) {
    return isPwa ? countryObj.prod_app_url : countryObj.prod_website_url;
  } else {
    return countryObj.website_url;
  }
}

function CountrySelectorContainer({ t }) {
  const isPwa = PwaService.getIsPwa();
  const { arabic } = useContext(globalConfigContext);
  const [countries, setCountries] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  const qparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // const onSelectLanguage = (eventKey) => {
  //     LanguageService.saveLanguage(eventKey);
  //     window.location.reload();
  // }

  useEffect(() => {
    GenericService.GetCountries()
      .then((response) => {
        if (response && response.status === 200) {
          setCountries(response.data.data.countries);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  const getCountryIcon = (country_id) => {
    switch (country_id) {
      case 1:
        return flagKsa;
      case 2:
        return flagUae;
      case 3:
        return flagOman;
      case 4:
        return flagBahrain;
      default:
        return flagBahrain;
    }
  };

  useGoogleAnalytics();

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  //     GenericService.GetLanguages().then(response => {
  //         if (response && response.data) {
  //             setLanguages(response.data.data);
  //         }
  //     })
  // }, []);

  const selectCountry = (country) => {
    // {country.website_url ? `${getTargetUrl(country)}${isPwa ? "?ispwa=true" : ""}` : "/"}
    let targetUrl = `${getTargetUrl(country, isPwa)}${
      isPwa ? "?ispwa=true" : ""
    }`;

    let hostname = window.location.hostname;
    let countryRedirectionUrl;

    if(hostname.includes("app")){
      if(targetUrl.includes("ksa")) {
        countryRedirectionUrl = "https://ksa-app.cinepolisgulf.com/"
      } else if (targetUrl.includes("uae")) {
        countryRedirectionUrl = "https://uae-app.cinepolisgulf.com/"
      } else if (targetUrl.includes("bahrain")) {
        countryRedirectionUrl = "https://bahrain-app.cinepolisgulf.com/"
      } else {
        countryRedirectionUrl = "https://oman-app.cinepolisgulf.com/"
      }
    }else{
      countryRedirectionUrl = targetUrl
    }

    CountryService.saveCountry({
      country_name: country.country_name,
      country_id: country.country_id,
      redirect_url: countryRedirectionUrl,
    });
    window.location.href = countryRedirectionUrl;
  };

  useEffect(() => {
    const savedCountry = CountryService.getCountry();
    if (
      isPwa &&
      savedCountry &&
      savedCountry.redirect_url &&
      (!qparams || !qparams.redirect || qparams.redirect !== "no")
    ) {
      window.location.href = savedCountry.redirect_url;
    } else {
      setShouldShow(true);
    }
  }, []);

  return shouldShow ? (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Select Country - Cinepolis</title>
        <meta
          name="description"
          content="Cinepolis cinemas is yet to open in the user's country"
        />
        <script type="text/javascript">
          {`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                        a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                        r.src=n;var u=t.getElementsByTagName(s)[0];
                        u.parentNode.insertBefore(r,u);})(window,document,
                        'https://sc-static.net/scevent.min.js');
                        snaptr('init', 'b401433c-64b2-4d33-8f52-17b66e9916b8', {
                        'user_email': ''
                        });
                        snaptr('track', 'PAGE_VIEW');`}
        </script>
        <script type="text/javascript">
          {String.raw`var ssaUrl = 'https://' + 'clickserv.sitescout.com/conv/fc8408632c59a9ec';new Image().src = ssaUrl; (function(d) {  var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">'); doc.close(); })(document);`}
        </script>
      </Helmet>
      <CustomerSpinner active={!isLoaded} />
      <div className="select-country-section">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div>
                <figure>
                  <img
                    src={cinepolisLogo}
                    className="cinepolis-logo"
                    alt="logo"
                  />
                </figure>
              </div>
              <div className="py-md-5 mt-md-4 country-selector-space">
                <h5 className="fw-bold">Choose your Country</h5>
                <p className="reset-font">اختر بلدك</p>
              </div>
              <div className="countries">
                {countries &&
                  countries.length > 0 &&
                  countries.map((country) => (
                    <a
                      href="#"
                      className="country d-flex align-items-center"
                      onClick={() => selectCountry(country)}
                    >
                      <div className="country-circle mb-3">
                        <img
                          src={getCountryIcon(country.country_id)}
                          className="flag"
                        />
                      </div>
                      <p className="frs-16">{country.country_name}</p>
                      <p className="fw-bold frs-16 reset-font">
                        {country.country_name_ar}
                      </p>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="py-2 footer-copyright">
        <div className="text-center">
          <p className="">{t("footer.copyright")}</p>
        </div>
        {/* <div className="selectcountryfootericon d-none">
          <i class="ri-facebook-fill"></i>
          <i class="ri-instagram-line"></i>
          <i class="ri-twitter-x-fill"></i>
        </div> */}
      </div>
      </div>
      
      
    </div>
  ) : (
    <CustomerSpinner active={!shouldShow} />
  );
}

export default withNamespaces()(CountrySelectorContainer);
