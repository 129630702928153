const timezones = {
    SA: 'Asia/Riyadh',
    AE: 'Asia/Dubai',
    BH: 'Asia/Bahrain',
    OM: 'Asia/Muscat'
}

export function getTimezoneWithCountryCode(country_code) {
    if(!country_code) return "UTC";

    if(timezones[country_code]) {
        return timezones[country_code];
    } else {
        return "UTC";
    }
}