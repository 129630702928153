import React from 'react'


import BlackCard from "@assets/pictures/club-cinepolis-new/loyalty-black.png";
import SilverCard from "@assets/pictures/club-cinepolis-new/loyalty-silver.png";

import { ReactComponent as ProfileBlack } from "@assets/pictures/club-cinepolis-new/profile-black.svg";
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import whiteProfile from "../../assets/pictures/svgs/white-profile.svg"
import blackProfile from "../../assets/pictures/svgs/black-profile.svg"
import {
    Card,
    ListGroup,
    Spinner,
    Alert,
    Form,
    Button,
    Modal,
    Row,
    Container,
    Col,
    Dropdown,
    InputGroup,
    DropdownButton,
    Accordion,
    Tabs,
    Tab,
  } from "react-bootstrap";

const LoyaltyRegisterStatic = ({containerSpacing}) => {
 const [nonOmanUser,setNonOmanUser] = useState(false);
 const user = useSelector((state) => state.user.current_user);
  console.log(user,"user>>")
 const userCountryCode = user?.mobile.slice(1,4)
    const history = useHistory(); 
    
    const handleUserRegister = () => {
        if(user && userCountryCode !== "968"){
         setNonOmanUser(true)
        }else{
         setNonOmanUser(false)
         history.push("/club-cinepolis-signup")
        }
      
      }

    return (
        <section className="section-bg profile-page">
            <div className={`${containerSpacing == false ? '' : 'container-fluid container-spacing'}`}>
                <div className="container-fluid club-cinepolis-about-main loyalty-register-static">
                    <div className="row mt-md-2">
                        <div style={{ marginTop: "50px" }} className="col-lg-4 col-12">
                            <div className="club-card-image position-relative">
                                <div className="club-card-black">
                                    <img src={BlackCard} alt="" />
                                </div>
                                <div style={{ top: "-35px", left: "90px", zIndex: "-1" }} className="club-card-silver position-absolute top-0 start-0">
                                    <img src={SilverCard} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-12 mt-lg-5 mt-sm-2">
                            <div className="join-club-text text-lg-center mt-3 mt-lg-0">
                                <p style={{color:"#373737"}} className='d-none d-md-block fs-20'>Join</p>
                                <h2 className='mb-1 display-6'>
                                    <b>Club Cinépolis</b>
                                </h2>
                                <p className='fs-20 fs-sm-14' style={{color:"#292929"}}>and unlock a world of rewards, discounts and offers!</p>
                                <p className="mb-3 small mt-2 mt-lg-0 fs-18 fs-sm-13" style={{color:"#525252"}}>
                                    Enjoy full or partial cinépolis Points redemption on movie
                                    tickets and F&B purchases
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-lg-4 mt-2">
                        <div className="col-lg-10">
                            <h4 className='points-generated'>
                                <b>Points generated per tier</b>
                            </h4>
                            <div className="d-lg-flex mt-3">
                                <div className="d-flex align-items-center align-items-md-baseline mt-3 mt-lg-0" style={{ gap: '16px' }}>
                                    <div className="black-tier loy-tier-img text-center d-flex justify-content-center align-items-center  " style={{flexBasis:"min-content", gap: "6px"}}>
                                        {/* <ProfileBlack style={{width:"20px"}} /> */}
                                        <img 
                                        alt='sorry'
                                        src={whiteProfile}
                                         width="30px"

                                        />
                                        <p className="text-center" style={{fontSize:"15px"}}>BLACK TIER</p>
                                    </div>
                                    <div className="mt-md-3 percent-main">
                                        <p className="fw-bold fs-2 percent-num"  style={{fontSize:"50px"}} >5%</p>
                                        <p className="d-block d-md-none" style={{fontSize:"10px",whiteSpace:"nowrap"}}>on ticket purchases and F&B purchases</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center align-items-md-baseline mt-3 mt-lg-0 " style={{ gap: "16px" }}>
                                    <div className="silver-tier loy-tier-img text-center d-flex align-items-center flex-row"  style={{flexBasis:"min-content", gap: "6px"}}>
                                    <img 
                                        alt='sorry'
                                        src={blackProfile}
                                         width="30px"

                                        />
                                        <p className="text-center" style={{fontSize:"15px"}}>SILVER TIER</p>
                                    </div>
                                    <div className="mt-md-3 percent-main">
                                        <p className="fw-bold fs-2 percent-num" style={{fontSize:"50px"}}>10%</p>
                                        <p className="d-block d-md-none" style={{fontSize:"10px",whiteSpace:"nowrap"}}>on ticket purchases and F&B purchases</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mt-md-5">
                        <div className="col-lg-10">
                            <div className="d-lg-flex align-items-center my-3 my-md-0">
                                <button className="btn px-4 fs-16 fs-m register-now"  style={{backgroundColor:"#8F8F8F",color:"white" }}onClick={handleUserRegister} disabled={user?.is_loyalty_active == "Y" ? true : false}>REGISTER NOW</button>
                                <p className="ml-lg-3 mt-2 mt-lg-0" style={{whiteSpace:"nowrap", fontSize:"14px",color:"grey"}}>to instantly become a Black Tier member</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 mt-md-5">
                        <div className="col-lg-10">
                            <h5>
                                <b>Registration Process</b>
                            </h5>
                            <ol className="mb-0 pl-3 mt-2 mt-lg-3">
                                <li className='mt-lg-2 text-grey'>Click Register Now</li>
                                <li className='mt-lg-2 text-grey'>Fill in your details in the form</li>
                                <li className='mt-lg-2 text-grey'>Once verified, you are now a member of Club Cinépolis</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row mt-4 mt-md-5 mb-4">
                        <div className="col-lg-6 ">
                            <h5>
                                <b>View your Details</b>
                            </h5>
                            <p className='mt-2 mt-lg-3 text-grey text-capitalize'>
                                To view your tier, Club Cinépolis Points and transaction history,
                                visit the Club Cinépolis section of your profile
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Modal
        show={nonOmanUser}
        onHide={setNonOmanUser}
        backdrop="static"
        keyboard={false}
        className="modal-content-checkout-tnc"
      >
        {/* <Modal.Header className="modal-header-tnc-review" closeButton> */}
          {/* <Modal.Title className="text-title-mobile">RESET YOUR PASSWORD</Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body className="modal-body-checkout-login">
        <Container fluid className="">
        <div className='d-flex justify-content-center align-items-center border-bottom mb-2 mt-2'>
        <p className="px-4 py-2" style={{fontSize:"30px"}}>Notice</p>
        </div>
          <p>Your mobile number cannot be part of Oman´s LOYALTY program due to country restrictions. Please use different email and a valid Oman phone number to be succesfully registered and start earning points.</p>
          <div className='d-flex justify-content-center align-items-center border-top mt-2'>
          <button className="blue-btn close-btn-tnc margin-auto mt-3 px-4" onClick={()=>setNonOmanUser(false)}>  Close</button>
          </div>

            </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <div className='d-flex justify-content-center align-items-center'>
          <Button className="blue-btn close-btn-tnc margin-auto" onClick={()=>setNonOmanUser(false)}>
            Close
          </Button></div>
        </Modal.Footer> */}
      </Modal>
        </section>
    )
}

export default LoyaltyRegisterStatic