import { combineReducers } from 'redux';
//imports multiple reducer here
import userReducer from './users/userReducer';
import quickBookReducer from './quickbook/quickbookReducer';
import reservationReducer from './reservation/reservationReducer';
import foodAndBeveragesReducer from './foodAndBeverages/foodAndBeveragesReducer';
import moviesReducer from './movies/moviesReducer';
import promotionsReducer from './promotions/promotionsReducer';
import bookingReducer from './booking/bookingReducer';
import cinemasReducer from './cinemas/cinemasReducer';
import configReducer from './config/configReducer';
import showInput from './showInput/configReducer'

const rootReducer = combineReducers({
    //and use it here
    reservation: reservationReducer,
    foodAndBeverages: foodAndBeveragesReducer,
    user: userReducer,
    quickBook: quickBookReducer,
    movies: moviesReducer,
    promotions: promotionsReducer,
    booking: bookingReducer,
    cinemas: cinemasReducer,
    config: configReducer,
    showInput : showInput
});

export default rootReducer;
