import { SET_SHOW_INPUT } from "./configTypes"

let initialState = {
    showInput: false,
}

const showInputReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SHOW_INPUT:
            return {
                ...state,
                showInput: action.payload,
            }
        default: return state;
    }
}

export default showInputReducer;