import "core-js/stable";
import "core-js/features/promise/all-settled";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import GlobalConfigContext from '@context/GlobalConfigContext';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LanguageService, PwaService} from '@apiService/tokenService';
import { isInStandaloneMode } from '@helper/utils'
import GenericService from '@apiService/GenericService';
import ComingSoonSelector from '@components/ComingSoonSelector';
import { getTrackingIdInfo } from '@helper/googleAnalytics';
import ReactGA from 'react-ga';
import qs from 'qs';
const language = LanguageService.getLanguage();

const qparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const lang_from_param = qparams.lang;

let isCountryDataLoaded = false;
let isCssFetched = false;

function stopMainSpinner() {
  let mainSpinner = document.getElementById('main-spinner');
  if(mainSpinner) {
    mainSpinner.style.display = "none";
  }
}

if (language == 'ar' || language == 'ab' || lang_from_param == 'ar') {
  import('@assets/css/App.css').finally((condition) => {
    isCssFetched = true;
    if(isCountryDataLoaded) {
      stopMainSpinner()
    }
  })
} else {
  import('@assets/css/App.css').finally((condition) => { 
    isCssFetched = true;
    if(isCountryDataLoaded) {
      stopMainSpinner()
    }
  })
}

const isPwa = isInStandaloneMode();
if(isPwa) {
  PwaService.setIsPwa(true);
}

GenericService.GetCountrySpecificData()
  .then(response => {
    if(response && response.status === 200) {
      const data = response.data.data; 
      if(data.is_under_maintainance === 'Y') {
        ReactDOM.render(<ComingSoonSelector />, document.getElementById('root'))
      } else {
        ReactDOM.render(
          <GlobalConfigContext>
            <Router>
              <App />
            </Router>
          </GlobalConfigContext>,
          document.getElementById('root')
        );
      }
    }
  })
  .catch(err => {
    ReactDOM.render(
      <GlobalConfigContext>
        <Router>
          <App />
        </Router>
      </GlobalConfigContext>,
      document.getElementById('root')
    );
  }).finally(() => {
    isCountryDataLoaded = true;
    if(isCssFetched) {
      stopMainSpinner()
    }
  });

// document.addEventListener("DOMContentLoaded", function(event) {
//       ReactDOM.render(
//         <GlobalConfigContext>
//           <Router>
//             <App />
//           </Router>
//         </GlobalConfigContext>,
//         document.getElementById('root')
//       );
//     });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
