import React from 'react';
import { Spinner as BSpinner } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';

function HomeSpinner({ active=true, t }) {
    return (
        active 
        ? (<div className="home-spiner-container">
            <BSpinner animation="border" role="status">
                <span className="sr-only">{t('loading')}</span>
            </BSpinner>
        </div>)
        : null
    )
}

export default withNamespaces()(HomeSpinner);