export const FETCH_SEAT_LAYOUT_LOADING = "FETCH_SEAT_LAYOUT_LOADING";
export const FETCH_SEAT_LAYOUT_SUCCESS = "FETCH_SEAT_LAYOUT_SUCCESS";
export const FETCH_SEAT_LAYOUT_ERROR = "FETCH_SEAT_LAYOUT_ERROR";
export const FETCH_SHOW_DETAILS_SUCCESS = "FETCH_SHOW_DETAILS_SUCCESS";
export const SET_SEAT_SELECTED = "SET_SEAT_SELECTED";
export const SET_OFFER_SEAT_SELECTED = "SET_OFFER_SEAT_SELECTED";
export const SET_RESERVATION_ID = "SET_RESERVATION_ID";
export const SET_SEAT_SELECT_ERROR = "SET_SEAT_SELECT_ERROR";
export const SET_TRANSACTION_ID = "SET_TRANSACTION_ID";
export const SET_TRANSACTION_ERROR = "SET_TRANSACTION_ERROR";
export const CLEAR_BOOKING_STATE = "CLEAR_BOOKING_STATE";
export const SET_TICKET_PRICE = "SET_TICKET_PRICE";
export const RESET_RESERVATION_ID = "RESET_RESERVATION_ID";
export const SET_GUEST_ID = "SET_GUEST_ID";
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";
export const SET_BOOKING_FEE_DATA = "SET_BOOKING_FEE_DATA";
export const SET_ADDITIONAL_DATA = "SET_ADDITIONAL_DATA";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_FOOD_AND_BEVERAGES_STATE = "CLEAR_FOOD_AND_BEVERAGES_STATE";
export const CLEAR_SELECTED_SEATS = "CLEAR_SELECTED_SEATS";
export const CLEAR_TIMEOUT_POPUP = "CLEAR_TIMEOUT_POPUP";
export const SET_VOUCHER_CODE_SUCCESS = "SET_VOUCHER_CODE_SUCCESS";
export const SET_VOUCHER_CODE_ERROR = "SET_VOUCHER_CODE_ERROR";
export const SET_SESSION_DETAILS = "SET_SESSION_DETAILS";
export const SET_BANK_OFFERS_LIST = "SET_BANK_OFFERS_LIST";
export const SET_SELECTED_BANK_OFFER = "SET_SELECTED_BANK_OFFER";
export const REMOVE_SELECTED_BANK_OFFER = "REMOVE_SELECTED_BANK_OFFER";

export const BANK_OFFER = "BANK_OFFER";
export const SET_OFFERTYPE = "SET_OFFERTYPE";
export const VOUCHER = "VOUCHER";
export const SET_REMAINING_SEATS_LIMIT = "SET_REMAINING_SEATS_LIMIT";
export const CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS = "CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS";

