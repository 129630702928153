import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

function useUserDetails() {
    const user = useSelector((state) => state.user.current_user);
    const guest_user = useSelector((state) => state.user.guest_user);

    let first_name = user ? user.first_name : guest_user ? guest_user.guest_first_name : "";
    let last_name = user ? user.last_name : guest_user ? guest_user.guest_last_name : "";
    let email = user ? user.email : guest_user ? guest_user.guest_email : "";
    let mobile = user ? user.mobile : guest_user ? guest_user.guest_mobile_number : "";
    let is_loyalty_active = user ? user.is_loyalty_active : guest_user ? guest_user.is_loyalty_active : "";
    let MemberLevelName = user ? user.MemberLevelName : guest_user ? guest_user.MemberLevelName : "BLACK";

    return {
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        is_loyalty_active: is_loyalty_active ? is_loyalty_active : "",
        tier: MemberLevelName ? MemberLevelName : "BLACK"
    };
}

export default useUserDetails;
