import React, { useState, useEffect } from 'react';
import GenericService from '@apiService/GenericService';

function useCountries(currentCountryCode, setCurrentCountryCode) {
	const [countries, setCountries] = useState(null);

	useEffect(() => {
		GenericService.GetCountries({ all: "all" })
			.then((response) => {
				if (response && response.status === 200) {
					const { data } = response.data;
					let countries = data.countries;
					let current_country_id = data.current_country_id;
					let current_country_code = countries
						? countries.find((country) => country.country_id == current_country_id)?.country_code
						: null;

					setCountries(countries);
					if(setCurrentCountryCode && !currentCountryCode) {
						setCurrentCountryCode(prev => prev ? prev : `${current_country_code}`);
					}
				}
			})
			.catch((err) => console.log(err));
	}, []);

	return countries;
}

export default useCountries;
