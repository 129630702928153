import React from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { LANDING_PAGE, APP_LANDING_PAGE } from '@config';
import { PwaService } from '@apiService/tokenService';
import {Helmet} from "react-helmet";
import useGoogleAnalytics from '../customHooks/useGoogleAnalytics.js';
import cinepolisLogo from '../assets/pictures/svgs/logo-cinepolis.svg';
import leftArrow from '../assets/pictures/svgs/left-arrow.svg';
import cinepolisBanner from "../assets/pictures/cinepolis-banner.jpeg"
import playStore from '../assets/pictures/svgs/google-play-badge.svg';
import appStore from '../assets/pictures/svgs/app-store-badge.svg';
import useUserDetails from "../customHooks/useUserDetails";

function getTargetUrl(countryObj) {
    let hostname = window.location.hostname;

    if(hostname === 'cinepolis-app-dev.binarynumbers.io') {
        return countryObj.website_url;
    } else if (hostname === 'uat-cinepolis.cinepolisgulf.com') {
        return countryObj.uat_website_url;
    } else if (hostname === 'app.cinepolisgulf.com') {
        return countryObj.prod_website_url;
    } else {
        return countryObj.website_url;
    }
}

function CountrySelectorContainer({ t }) {
    const isPwa = PwaService.getIsPwa();
    const history = useHistory();
    const redirect_url = (isPwa ? APP_LANDING_PAGE : LANDING_PAGE) + "?redirect=no";

    useGoogleAnalytics();

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Coming Soon - Cinepolis</title>
                <meta name="description" content="Cinepolis cinemas is yet to open in the user's country" />
                <script type='text/javascript'>
                    {`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                    r.src=n;var u=t.getElementsByTagName(s)[0];
                    u.parentNode.insertBefore(r,u);})(window,document,
                    'https://sc-static.net/scevent.min.js');
                    snaptr('init', 'b401433c-64b2-4d33-8f52-17b66e9916b8', {
                    'user_email': ''
                    });
                    snaptr('track', 'PAGE_VIEW');`}
                </script>
                <script type="text/javascript">
                    {String.raw`var ssaUrl = 'https://' + 'clickserv.sitescout.com/conv/fc8408632c59a9ec';new Image().src = ssaUrl; (function(d) {  var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">'); doc.close(); })(document);`}
                </script>
            </Helmet>
                <div className="select-country-section position-relative">
                    <img src={leftArrow} className="back-arr d-md-none" onClick={() => (window.location.href = redirect_url )} />
                    <div className="w-100">
                        <div className="mx-auto py-4 py-md-4">
                            <img src={cinepolisLogo} className="cinepolis-logo" alt="logo"/>
                        </div>
                        <div className="cinepolis-banner">
                            <img src={cinepolisBanner} className="cinepolis-banner img-responsive" alt="banner" width="96%"/>
                    </div>
                        {/* <div className="pb-4 pb-md-5 pt-md-4 mt-md-4">
                            <p className="frs-20 fw-reg">{t('pcy')}</p>
                            <p className="frs-24 fw-bold pcyc">{t('country')}</p>
                        </div> */}
                        {/* <div className="countries">
                                        <a href={country.website_url ? `${getTargetUrl(country)}${isPwa ? "?ispwa=true" : ""}` : "/"} className="country d-flex align-items-center">
                                            <div className="country-circle mb-3">
                                                <img src={getCountryIcon(country.country_id)} className="flag"/>
                                            </div>
                                            <p className="fw-bold frs-16">{country.country_name}</p>
                                            <p className="fw-bold frs-16">{country.country_name_ar}</p>
                                        </a>
                        </div> */}
                        {/* <img src={maintenance} className="img-fluid mb-4 mb-md-5 pt-md-5 px-5"/> */}
                    </div>
                    <div className="copyright py-1  py-md-1 bottom-fixed d-flex flex-sm-column align-items-center">
                        {!isPwa && 
                        <div className="d-flex align-items-center mb-n4">
                            <img src={playStore} width="150" className="mr-3"
                                onClick={() => (window.location.href = "https://play.google.com/store/apps/details?id=nz.co.vista.android.movie.cinepolisbahrain&hl=en")}
                            />
                            <img src={appStore} width="150" className=""
                                onClick={() => (window.location.href = "https://apps.apple.com/us/app/cin%C3%A9polis-gulf/id1449779192?ls=1")}
                            />
                        </div>}
                        <p className="fw-reg frs-12 frm-16">{t('footer.copyright')}</p>
                    </div>
                </div>
        </div>

        );
}

export default withNamespaces()(CountrySelectorContainer);