import Api from './Api';

export default {
    GetUsers() {
        return Api().get(`users`);
    },
    AddUser(payload) {
        return Api().post(`users`, payload);
    },
    GetSingleUserDetails(payload) {
        return Api().get(`users/${payload.user_id}`);
    },
    Login(payload) {
        // return Api().post(`http://localhost:8001/api/login`, payload);
        return Api().post(`/login`, payload);
    },
    Signup(payload) {
        // return Api().post(`http://localhost:8001/api/register-loyalty`, payload);
        return Api().post(`/register-loyalty`, payload);
    },
    LoyaltyRegisterExistingUser(payload) {
        // return Api().post(`http://localhost:8001/api/register-loyalty-existing`, payload);
        return Api().post(`/register-loyalty-existing`, payload);
    },
    LoyaltyRegisterExistingUserLogin(payload) {
        // return Api().post(`http://localhost:8001/api/register-loyalty-existing`, payload);
        return Api().post(`/register-loyalty-existing-login`, payload);
    },
    VerifyUser(payload) {
        // console.log(payload, "payyyyyyyyyyyyyyyljkgfsd")
        // return Api().post(`http://localhost:8001/api/verifyUser`, payload);
        return Api().post(`/verifyUser`, payload);
    },
    GetUserDetails() {
        // return Api().get(`http://localhost:8001/api/user/user-details-loyalty`);
        return Api().get(`/user/user-details-loyalty`);
    },

    deleteExistingUser(payload) {
        return Api().post(`/user/delete-user`, payload);
    },
    SaveGuest(payload) {
        return Api().post(`/guest`, payload);
    },
    GetUserProfile() {
        return Api().get(`/user/user-profile`);
    },
    UpdateUserProfile(payload) {
        return Api().put(`/user/user-profile`, payload)
    },
    GetUserBookings() {
        return Api().get(`/user/user-bookings`);
    },
    ResetPassword(payload) {
        return Api().post(`/user/reset-password`, payload)
    },
    EmailTicket(payload) {
        return Api().post(`/user/email-ticket`, payload);
    },
    SocialLogin(payload) {
        return Api().post(`/social-login`, payload);
    },
    getLoyaltyTransections(payload) {
        return Api().post(`/user/loyalty-transction-history`, payload);
    },
};
