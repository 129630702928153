import axios from 'axios';
import { BASEURL } from "../config/index";
import { TokenService, CityService, PwaService, CountryService } from "./tokenService";

const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: `${BASEURL}`
    })

    const token = TokenService.getToken();
    if (token) {
        instance.defaults.headers.common["Authorization"] = token;
    }
    // instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    const isPwa = PwaService.getIsPwa();
    if(isPwa !== null || isPwa !== undefined) {
        instance.defaults.headers.common["ISPWA"] = isPwa;
    }

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response && error.response.status === 401) {
            TokenService.removeToken();
        }
        return Promise.reject(error);
    });

    instance.interceptors.request.use(function (config) {
        console.log(config);
        const city_data = CityService.getCity();
        if (city_data) {
            const { cityId } = city_data;
            config.headers.CITY_ID = cityId;
        }
        // const country_data = CountryService.getCountry();
        // let country_id_data = window.location.href.includes("ksa") ? "1" : window.location.href.includes("uae") ? "2" : window.location.href.includes("oman") ? "3" : window.location.href.includes("bahrain") ? "4" : "1"

        // if (country_data) {
        //     const separator = config.url.includes('?') ? '&' : '?';
        //     config.url += `${separator}country_id=${country_data?.country_id}`;
        // }

        // if(country_id_data) {
        //     const separator = config.url.includes('?') ? '&' : '?';
        //     config.url += `${separator}country_id=${country_id_data}`;
        // }

        return config;
    });

    return instance;
}

export default createAxiosInstance;
