import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
// import LazyImage from "react-hook-lazy-image";
import { fetchShowDetails } from "@store/booking/bookingActions";
import moment from "moment";
import momenttz from "moment-timezone";
import { getTimezoneWithCountryCode } from "@helper/time";
import AuthService from "../ApiServices/AuthService.js";

import { globalConfigContext } from "@context/GlobalConfigContext";
import SpiderManLogo from "../assets/pictures/spiderman_1200_579.jpg";
import SpiderManLogoSmall from "../assets/pictures/spider_300_400.jpg";
// import ReactPlayer from "react-player";
import {
  isImageURLNull,
  titleCase,
  translateSessionAttribute,
} from "@helper/formatting";
import co1 from "../assets/pictures/co1.png";
import ko from "../assets/pictures/ko.png";
import rightArrow from "../assets/pictures/svgs/right-arrow.svg";
import arrowUp from "../assets/pictures/svgs/arrow-up.svg";
import share from "../assets/pictures/svgs/share.svg";
import cross from "../assets/pictures/svgs/cross.svg";
// import movieDef from '../assets/pictures/svgs/movie_default.svg';
import movieDef from "../assets/pictures/movie-default-portrait.jpg";
import movieDefLand from "../assets/pictures/movie-default-landscape.jpg";
import leftArrow from "../assets/pictures/svgs/left-arrow.svg";
import cinepolisSmall from "@assets/pictures/cinepolis-small.png";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode";
import { withNamespaces } from "react-i18next";
import round from "lodash/round";
import { Helmet } from "react-helmet";
import useGoogleAnalytics from "../customHooks/useGoogleAnalytics";
import useUserDetails from "../customHooks/useUserDetails";
import { findLast } from "lodash";

var opts = {
  errorCorrectionLevel: "H",
  type: "image/jpeg",
  quality: 0.3,
  margin: 1,
  width: "150",
  color: {
    dark: "#000000",
    light: "#FFFFFF",
  },
};

function FinalTicketContainer(props) {
  const t = props.t;
  const { arabic, currency, country_data } = useContext(globalConfigContext);
  const { reservation_id, relation } = props.match.params;
  console.log(reservation_id, relation, "oooooooo");
  useGoogleAnalytics();

  const vidRef = useRef(null);

  const dispatch = useDispatch();
  const showDetailsObj = useSelector((state) => state.booking.show_details);
  const showDetails = useSelector((state) =>
    state.booking.show_details && state.booking.show_details.details
      ? state.booking.show_details.details
      : {}
  );
  const [qrcodeData, setQrcodeData] = useState(null);
  const [slShrink, setSlShrink] = useState(false);
  const [accordionArrow, setAccordionArrow] = useState(true);
  const handleSlShrinkModal = () => setSlShrink(false);
  const history = useHistory();
  const { email } = useUserDetails();

  const [GiftTicketMail, setGiftTicketMail] = useState(null);

  const [selctedRelation, setSelectedRalation] = useState("");

  const [isShowTicket, setIsShowTicket] = useState(true);

  const [isgiftTicketShow, setIsGiftTicketShow] = useState(false);
  const [ralations, setRelations] = useState(["Brother", "Sister", "Wife"]);

  const [isProceedGiftTicket, setIsProceedGiftTicket] = useState(false);

  const [haidTicket, setHaidticket] = useState(true);

  const [urlStore, setUrlStore] = useState(null);

  const bookingFeeTax = showDetails
    ? round(
      showDetails.booking_fee -
      showDetails.booking_fee / (1 + showDetails.tax_percent / 100),
      2
    )
    : 0;

  const redirectToHome = () => {
    history.push("/");
  };

  const movie_id_ticket = useSelector((state) => state.movies.movie_details.ID);

  const bookingData = useSelector(
    (state) => state.booking?.show_details?.details
  );

  useEffect(() => {
    if (movie_id_ticket == "HO00001061") {
      setIsShowTicket(true);
    }
  }, []);

  useEffect(() => {
    if (relation == "AUNT") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_AUNT_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "BROTHER") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_BROTHER_TXL_SPLT_Pro%20Res.mp4"
      );
    } else if (relation == "FRIEND") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_FRIEND_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "GIRLFRIEND") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_GIRLFRIEND_TXL_6%20CH_SPLT_PRO%20RES.mp4"
      );
    } else if (relation == "HUSBAND") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_HUSBAND_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "NEIGHBOR") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_NEIGHBOUR_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "BOYFRIEND") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_BOYFRIEND_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "DAD") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_DAD_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "UNCLE") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_UNCLE_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "WIFE") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_WIFE_TXL_6%20CH_SPLT_PRO%20RES.mp4"
      );
    } else if (relation == "SISTER") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_SISTER_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "MOM") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_MOM_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "BESTIE") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_BESTIE_TXD_STR_Pro%20Res.mp4"
      );
    } else if (relation == "PARTNER") {
      setUrlStore(
        "https://uat-api-cinepolis.cinepolisgulf.com/uploads/videos/SMNWH_Grtg_BEL_Xmas_PARTNER_TXD_STR_Pro%20Res.mp4"
      );
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    let intervalHandle = null;

    dispatch(fetchShowDetails({ reservation_id }, redirectToHome))
      .then((data) => {
        if (data.message.toLowerCase() === "pending") {
          intervalHandle = setInterval(async () => {
            try {
              const detailsResponse = await dispatch(
                fetchShowDetails({ reservation_id }, redirectToHome)
              );
              if (detailsResponse.message.toLowerCase() !== "pending") {
                if (intervalHandle) {
                  clearInterval(intervalHandle);
                }
              }
            } catch (err) {
              if (intervalHandle) {
                clearInterval(intervalHandle);
              }
            }
          }, 1000);
        } else if (data.message.toLowerCase() === "failed") {
          if (
            data.details &&
            data.details.wrong_card_number &&
            data.details.wrong_card_number === "Y"
          ) {
            if (
              data.details.payment_status &&
              data.details.payment_status.toLowerCase() === "refunded"
            ) {
              history.push("/transaction-failed/?wc=Y&ar=Y");
            } else {
              history.push("/transaction-failed/?wc=Y");
            }
          } else {
            history.push("/transaction-failed");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/");
      });

    return () => {
      if (intervalHandle) {
        clearInterval(intervalHandle);
      }
    };
  }, [reservation_id]);

  useEffect(() => {
    if (showDetails && showDetails.bookingId) {
      QRCode.toDataURL(showDetails.bookingId, opts, function (err, url) {
        if (err) throw err;
        setQrcodeData(url);
      });
    } else {
      setQrcodeData(null);
    }
  }, [showDetails]);

  if (
    showDetailsObj &&
    showDetailsObj.message &&
    showDetailsObj.message.toLowerCase() === "pending"
  ) {
    return <h1>{t("final_ticket_page.Pending Please wait")}</h1>;
  }

  if (
    showDetailsObj &&
    showDetailsObj.message &&
    showDetailsObj.message.toLowerCase() === "failed"
  ) {
    return <Redirect to="/transaction-failed" />;
  }

  if (
    showDetailsObj &&
    showDetailsObj.message &&
    showDetailsObj.message.toLowerCase() === "booking in process"
  ) {
    return <h1>{t("final_ticket_page.Booking in process")}</h1>;
  }

  // useEffect(()=>{
  //   const response =  AuthService.getGiftMovieTicket();
  //   console.log(response.data, "jjjjjjjjjjj")
  // }, [])

  // console.log( "jjjjjjjjjjj")

  const onProceedGiftTicket = () => {
    AuthService.sendGiftMovieTicket({
      email: GiftTicketMail,
      relation: selctedRelation,
      reservationID: bookingData?.reservation_id,
    });
    console.log("lllllllllll");
    setIsProceedGiftTicket(true);
    setIsGiftTicketShow(false);
    setIsShowTicket(false);
  };

  const onSelectrelation = (event) => {
    setSelectedRalation(event);
  };

  // const palyVideo = () => {
  //   alert("hello")
  // }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Your Ticket - Cinepolis</title>
        <meta
          name="description"
          content="Final ticket page where the user can view his booking details"
        />
        <script>{`fbq('track', 'Purchase');`}</script>

        <script type="text/javascript">
          {`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                        a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                        r.src=n;var u=t.getElementsByTagName(s)[0];
                        u.parentNode.insertBefore(r,u);})(window,document,
                        'https://sc-static.net/scevent.min.js');

                        snaptr('init', 'b401433c-64b2-4d33-8f52-17b66e9916b8', {
                        'user_email': '${email}'
                        });

                        snaptr('track', 'PURCHASE');`}
        </script>

        {showDetails && showDetails.bookingId && (
          <script type="text/javascript">{`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-664073591/qE56COzprPsBEPfq07wC', 'transaction_id': '${showDetails.bookingId}', 'event_callback': callback }); return false; }`}</script>
        )}

        <script type="text/javascript">
          {String.raw`var ssaUrl = 'https://' + 'clickserv.sitescout.com/conv/0d79b5407afb1602';new Image().src = ssaUrl; (function(d) {  var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">'); doc.close(); })(document);`}
        </script>

        {/* window.location.hostname === "ksa.cinepolisgulf.com" &&  */}
        {window.location.hostname === "ksa.cinepolisgulf.com" && (
          <script type="text/javascript">{`var url='https://event-track.memob.com/conv-pixel?id=CINEPOLIS&action=THANKYOU'; var t=new Date().getTime(); var ut=url+'&rand='+t; var it=document.createElement('img'); it.src=ut; it.border=0;`}</script>
        )}
        {/* window.location.hostname === "ksa.cinepolisgulf.com" &&  */}
        {window.location.hostname === "ksa.cinepolisgulf.com" &&
          showDetails &&
          showDetails.number_of_seats &&
          showDetails.transaction_net_price &&
          showDetails.bookingId &&
          currency &&
          currency.divided_by && (
            <script type="text/javascript">
              {`gtag('event', 'purchase', {
                            'allow_custom_scripts': true,
                            'value': '${showDetails.transaction_net_price /
                currency.divided_by
                }',
                            'transaction_id': '${showDetails.bookingId}',
                            'quantity': '${showDetails.number_of_seats}',
                            'send_to': 'DC-10558311/ksa_s0/thank0+items_sold'
                        });`}
            </script>
          )}
        {/* window.location.hostname === "ksa.cinepolisgulf.com" &&  */}
        {window.location.hostname === "ksa.cinepolisgulf.com" &&
          showDetails &&
          showDetails.number_of_seats &&
          showDetails.transaction_net_price &&
          showDetails.bookingId &&
          currency &&
          currency.divided_by && (
            <noscript>
              {`<img src="https://ad.doubleclick.net/ddm/activity/src=10558311;type=ksa_s0;cat=thank0;qty=${showDetails.number_of_seats
                };cost=${showDetails.transaction_net_price / currency.divided_by
                };dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=${showDetails.bookingId
                }?" width="1" height="1" alt=""/>`}
            </noscript>
          )}
      </Helmet>
      <Container fluid className="py-3 mb-4 d-none d-md-block">
        {/* Temp */}

        {/* <div className="d-flex justify-content-center pt-5">
          <span className="blue-btn mxh-47 w-25 px-3 frl-16 frs-14">
            Sent From {bookingData ? bookingData?.customer_email : null}
          </span>
        </div> */}

        {/* Temp */}

        <div style={{ marginBottom: "20px" }}>
          <div className="d-block d-md-none">
            <div className="d-flex justify-content-between align-items-center px-4 pt-3 border-bottom pb-3">
              <span className="frl-22 frs-14 font-weight-bold">
                <img className="mxh-47" src={cinepolisSmall} alt="logo" />
              </span>
              <span
                onClick={() => redirectToHome()}
                className="blue-btn mxh-47 px-3 frl-16 frs-14"
              >
                HOME
              </span>
            </div>
          </div>
          {isProceedGiftTicket && (
            <div className="d-flex justify-content-center pt-5">
              <span className="blue-btn mxh-47 w-25 px-3 frl-16 frs-14">
                Gift Card send successfully
              </span>
            </div>
          )}
        </div>

        <Row>
          <Col md={8} lg={8} className="mx-auto">
            <Row className="bg-pb d-flex align-items-center py-2">
              <Col className={`${arabic ? "text-right" : "text-left"}`}>
                <img className="mxh-47" src={cinepolisSmall} alt="logo" />
              </Col>
            </Row>
            <div className="d-block d-md-none">
              <div className="d-flex justify-content-between align-items-center px-4 pt-3 border-bottom pb-3">
                <span className="frl-22 frs-14 font-weight-bold">
                  <img className="mxh-47" src={cinepolisSmall} alt="logo" />
                </span>
                <span
                  onClick={() => redirectToHome()}
                  className="blue-btn mxh-47 px-3 frl-16 frs-14"
                >
                  HOME
                </span>
              </div>
            </div>
            <div className="gift-ticket-video">
              <video
                ref={vidRef}
                id="autoPlayVideo"
                width="100%"
                autoPlay
                controls
                muted
                playsInline
              >
                <source src={urlStore} type="video/mp4"></source>
              </video>
            </div>
            <Row>
              <Col className="px-0">
                <div className="hero">
                  <div className="imgOverlay d-flex justify-content-center mxh-340">
                    {/* <LazyImage
                                            className="mxh-340"
                                            style={{ height: "340px", width: "100%" }}
                                            src={(showDetails && showDetails.movie_image_url_2 && !showDetails.movie_image_url_2.endsWith('null')) ? showDetails.movie_image_url_2 : ""}
                                            defaultSrc={require('../assets/pictures/default-img.png')}
                                        /> */}
                    <img
                      className=""
                      src={
                        !isImageURLNull(showDetails.movie_image_url_2)
                          ? showDetails.movie_image_url_2
                          : movieDefLand
                      }
                    />
                  </div>
                  <div className="hero-text px-3 d-flex flex-column justify-content-end">
                    <Container fluid>
                      <Row className="pb-4">
                        <Col md={8} xl={6}>
                          <Row className="pb-3">
                            <Col>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    showDetails.movie_image_url_1 &&
                                      !showDetails.movie_image_url_1.endsWith(
                                        "null"
                                      )
                                      ? showDetails.movie_image_url_1
                                      : movieDef
                                  }
                                  alt={showDetails.movie_title}
                                  className="mxh-128"
                                />
                                <div
                                  className={`d-flex flex-column justify-content-end kumbh fw-reg frxl-12 frs-10 ${arabic ? "pr-3" : "pl-3"
                                    }`}
                                >
                                  <p
                                    className={`fw-bold frxl-18 frs-16 ${arabic ? "text-right" : "text-left"
                                      }`}
                                  >
                                    {arabic
                                      ? showDetails.movie_title_ar
                                      : showDetails.movie_title}
                                  </p>
                                  <p
                                    className={`mb-2 ${arabic ? "text-right" : "text-left"
                                      }`}
                                  >
                                    {arabic
                                      ? showDetails.cinema_name_ar
                                      : titleCase(showDetails.cinema_name)}
                                  </p>
                                  <p
                                    className={`mb-2 ${arabic ? "text-right" : "text-left"
                                      }`}
                                  >
                                    {moment
                                      .utc(showDetails.Showtime)
                                      .format("ddd, Do, MMM, YYYY h:mm A")}
                                  </p>
                                  <p
                                    className={`mb-2 ${arabic ? "text-right" : "text-left"
                                      }`}
                                  >
                                    {t("final_ticket_page.Seats")}{" "}
                                    {showDetails.seats_name}
                                  </p>
                                  <p
                                    className={`${arabic ? "text-right" : "text-left"
                                      }`}
                                  >
                                    {showDetails.SessionAttribute
                                      ? translateSessionAttribute(
                                        arabic,
                                        showDetails.SessionAttribute
                                      )
                                      : translateSessionAttribute(
                                        arabic,
                                        "premium"
                                      )}
                                    ,{" "}
                                    {showDetails &&
                                      (arabic
                                        ? showDetails.ScreenNameAlt
                                        : titleCase(showDetails.ScreenName))}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="py-3">
                            <Col className="d-flex align-items-center">
                              {/* <span
                                                                className={`blue-btn ls-2 frxl-14 frs-12 blurred-white mxh-47 px-3 px-lg-4 ${arabic ? "ml-2" : "mr-2"}`}
                                                            >
                                                                {t('final_ticket_page.SAVE TICKET')}
                                                        </span> */}
                              {/* <span
                                                                className={`blue-btn frxl-14 frs-12 ls-2 blurred-white mxh-47 px-3 px-lg-4 ${arabic ? "mr-2" : "ml-2"}`}
                                                            >
                                                                {t('final_ticket_page.SHARE TICKET')}
                                                                <img src={share} alt="share" className={`mxh-24 ${arabic ? "mr-2" : "ml-2"}`}/>
                                                        </span> */}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={4}
                          xl={6}
                          className="d-flex align-items-start justify-content-end"
                        >
                          {qrcodeData && (
                            <div className="">
                              <img src={qrcodeData} className="img-150" />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="bg-light-black py-5 ">
              <Col md={7} lg={8} className="br-1">
                <div className="px-3 frxl-20 frl-18 frs-16 text-light kumbh fw-reg">
                  <p
                    className={`ls-2 fw-bold pb-3 bb-1 mb-3 ${arabic ? "text-right" : "text-left"
                      }`}
                  >
                    {t("final_ticket_page.Ticket-Details")}
                  </p>
                  <div className="d-flex justify-content-between align-items-center pb-3 bb-1 mb-3">
                    <span className={`${arabic ? "text-right" : "text-left"}`}>
                      {t("final_ticket_page.Tickets")}(x
                      {showDetails.number_of_seats}) - {showDetails.seats_name}
                    </span>
                    {/* <span
                      className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${arabic ? "text-right" : "text-left"
                        }`}
                    >
                      {(
                        (showDetails.ticket_price_in_cents_before_offer -
                          showDetails.ticket_tax_in_cents_before_offer) /
                        currency.divided_by
                      ).toFixed(2)}{" "}
                      {arabic
                        ? currency.currency_short_code_ar
                        : currency.currency_short_code}
                    </span> */}
                  </div>
                  {showDetails.fnb_items_count > 0 && (
                    <>
                      <Accordion className="accordion-1 tnc-accordion ft-accordion pb-3 bb-1 mb-3">
                        <Card className="border-0 pb-0">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className={`${!accordionArrow ? "show" : "hidden"}`}
                            onClick={() =>
                              accordionArrow
                                ? setAccordionArrow(false)
                                : setAccordionArrow(true)
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <span
                                className={`frxl-18 frl-16 frs-14 ${arabic ? "text-right" : "text-left"
                                  } font-weight-normal`}
                              >
                                {t("final_ticket_page.Refreshments")} (x
                                {showDetails.fnb_items_count})
                                <img alt="" src={arrowUp} className="ml-3" />
                              </span>
                              <span
                                className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${arabic ? "text-right" : "text-left"
                                  }`}
                              >
                                {(
                                  (showDetails.fnb_price_in_cents -
                                    showDetails.fnb_taxes_in_cents) /
                                  currency.divided_by
                                ).toFixed(2)}{" "}
                                {arabic
                                  ? currency.currency_short_code_ar
                                  : currency.currency_short_code}
                              </span>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {showDetails &&
                                showDetails.fnbItems.map((item) => (
                                  <div>
                                    <div className="bg-light-black px-3 pt-2 d-flex justify-content-between align-items-center">
                                      <span
                                        className={`frm-12 frl-16 ${arabic ? "text-right" : "text-left"
                                          }`}
                                      >
                                        {arabic
                                          ? item.concession_name_ar
                                          : titleCase(
                                            item.concession_name
                                          )}{" "}
                                        (x
                                        {item.quantity})
                                      </span>
                                    </div>
                                    {item.modifiers &&
                                      item.modifiers.length > 0 && (
                                        <div className="bg-light-black pl-5">
                                          {item.modifiers.map((modifier) => (
                                            <span
                                              className={`d-block frm-12 frl-16 pt-2 ${arabic
                                                ? "text-right"
                                                : "text-left"
                                                }`}
                                            >
                                              {arabic
                                                ? modifier.modifier_name_ar
                                                : titleCase(
                                                  modifier.modifier_name
                                                )}{" "}
                                              (x{modifier.quantity})
                                            </span>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                                ))}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  )}

                  <div className="d-flex flex-column ">
                    <b>
                      Gifted By
                    </b>
                    <br />
                    <span>
                      <b> Relation :</b>  {" "} {relation ? relation : null}
                      {" "}
                    </span>
                    <span>
                      <b>Name :</b>  {" "} {bookingData ? bookingData?.guest_first_name : null}
                      {bookingData ? bookingData?.user_first_name : null}
                      {" "}
                    </span>
                    <span>
                      <b> Email : </b> {" "} {bookingData ? bookingData?.customer_email : null}
                      {" "}
                    </span>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center pb-3 bb-1 mb-3">
                    <span
                      className={`frxl-18 frl-16 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {t("final_ticket_page.Taxes")}
                      <br />
                      <span
                        className={`blurred-white frxl-14 frl-12 frm-10 frs-8 ${
                          arabic ? "text-right" : "text-left"
                        }`}
                      ></span>
                    </span>
                    <span
                      className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {(
                        (showDetails.transaction_before_offer_gross_price -
                          showDetails.transaction_before_offer_net_price -
                          showDetails.booking_fee +
                          bookingFeeTax) /
                        currency.divided_by
                      ).toFixed(2)}{" "}
                      {arabic
                        ? currency.currency_short_code_ar
                        : currency.currency_short_code}
                    </span>
                  </div> */}
                  {/* <div className="d-flex justify-content-between align-items-center pb-3 bb-1 mb-3">
                    <span
                      className={`frxl-18 frl-16 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {t("final_ticket_page.Booking Fee")}
                    </span>
                    <span
                      className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {(
                        (showDetails.booking_fee - bookingFeeTax) /
                        currency.divided_by
                      ).toFixed(2)}{" "}
                      {arabic
                        ? currency.currency_short_code_ar
                        : currency.currency_short_code}
                    </span>
                  </div> */}
                  {/* {(showDetails.ticket_discount_price_in_cents || 0) > 0 && (
                    <div className="pb-3 bb-1 mb-3 d-flex justify-content-between align-items-center">
                      <span
                        className={`frxl-18 frl-16 frs-14 ${arabic ? "text-right" : "text-left"
                          }`}
                      >
                        {t("checkout_page.discount-applied")}
                      </span>
                      <span
                        className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${arabic ? "text-right" : "text-left"
                          }`}
                      >
                        {(
                          (showDetails.ticket_discount_price_in_cents || 0) /
                          currency.divided_by
                        ).toFixed(2)}{" "}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </span>
                    </div>
                  )} */}
                  {/* <div className="pb-3 d-flex justify-content-between align-items-center bb-1">
                    <span
                      className={`frxl-18 frl-16 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {t("final_ticket_page.Total")}
                    </span>
                    <span
                      className={`fw-bold frxl-24 frl-22 frm-18 frs-14 ${
                        arabic ? "text-right" : "text-left"
                      }`}
                    >
                      {(
                        showDetails.transaction_net_price / currency.divided_by
                      ).toFixed(2)}{" "}
                      {arabic
                        ? currency.currency_short_code_ar
                        : currency.currency_short_code}
                    </span>
                  </div> */}
                </div>
              </Col>
              <Col md={5} lg={4} className="">
                <div className="px-4 kumbh fw-reg">
                  <div className="d-flex flex-column mt-3 mb-5">
                    <span className="blurred-white">
                      {t("final_ticket_page.show-time-date")}

                    </span>
                    <span className="fw-bold frxl-28 frl-24 frs-20">
                      {moment
                        .utc(showDetails.Showtime)
                        .format("ddd, Do, MMM, YYYY h:mm A")}
                    </span>
                  </div>
                  <div className="d-flex flex-column my-5">
                    <span className="blurred-white">
                      {t("final_ticket_page.Seats")}
                    </span>
                    <span className="fw-bold frxl-28 frl-24 frs-20">
                    {showDetails.seats_name}
                    </span>
                  </div>
                  {/* {showDetails.payment_option && (
                    <div className="d-flex flex-column my-5">
                      <span className="blurred-white">
                        {t("final_ticket_page.Payment Method")}
                      </span>
                      <span className="fw-bold frxl-28 frl-24 frs-20">
                        {showDetails.payment_option}
                      </span>
                    </div>
                  )} */}
                  <div className="d-flex flex-column">
                    <span className="blurred-white">
                      {t("final_ticket_page.Booking ID")}
                    </span>
                    <span className="fw-bold frxl-28 frl-24 frs-20">
                      {showDetails.bookingId}
                    </span>
                  </div>
                </div>
              </Col>
              {country_data && country_data.country_short_code === "BH" && (
                <p className="fp-covid-note pt-5 w-100 px-3">
                  {/* {t("final_ticket_page.covid_note")} */}
                </p>
              )}
              {country_data && country_data.country_short_code === "AE" && (
                <>
                  <p className="fp-covid-note pt-5 w-100 px-3">
                    {/* {t("final_ticket_page.fnb_note_title")} */}
                  </p>
                  <p className="fp-covid-note pt-1 w-100 px-3">
                    {t("final_ticket_page.fnb_note_body")}
                  </p>
                </>
              )}
            </Row>

            {/* <Row className="bg-light-black">
                            <Col className="d-flex justify-content-between align-items-center">
                                <p className="ls-3 frxl-24 frl-22 frs-18 kumbh fw-bold p-3">{t('final_ticket_page.IMPORTANT INSTRUCTIONS')}</p>
                                <img src={arabic ? leftArrow : rightArrow} alt="arrow" className="mxh-29" />
                            </Col>
                        </Row> */}
          </Col>
        </Row>
      </Container>

      {/* Mobile design */}

      {/* <section className="history-details d-flex d-md-none">
                        <div className="top">
                            <div className="movie-bg">
                                <img src={!isImageURLNull(showDetails.movie_image_url_2) ? showDetails.movie_image_url_2 : movieDefLand} className="" alt="movie" />
                            </div>
                            <div className="oi">
                                <img src={arabic ? rightArrow : leftArrow}className="icon-18 my-4" alt="arrow" onClick={() => history.push('/')} />
                                <div className="ticket">
                                    <div className="d-flex justify-content-between py-2">
                                    <p className="md hd">{t('final_ticket_page.YOUR TICKET')}</p>
                                     <img src={cross} className="icon-18" alt="close"/> 
                                    </div>
                                    <div className="qr-row py-3 mb-3">
                                        <p className={`lg ${arabic ? "pl-2" : "pr-2"}`}>{ arabic ? showDetails.movie_title_ar : showDetails.movie_title} {showDetails.SessionDimension ? '(' + showDetails.SessionDimension + ')' : ""} ({showDetails.movie_lang && showDetails.movie_lang.substring(0, 2).toUpperCase()})</p>
                                        <div className="qr-img">
                                            <img src={qrcodeData} />
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex mb-2">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Cinema')}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Date')} &amp; {t('final_ticket_page.Time')}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex mb-4">
                                        <Col xs={6}className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="md">{titleCase(showDetails.cinema_name)}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="md">
                                                <p>{moment.utc(showDetails.Showtime).format("h:mm A")}</p>
                                                <p>{moment.utc(showDetails.Showtime).format("ddd, Do, MMM, YYYY")}</p></p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex mb-2">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Experience')}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Seats')}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="md">{showDetails.SessionAttribute ? translateSessionAttribute(arabic, showDetails.SessionAttribute) : translateSessionAttribute(arabic, "premium")}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="md">{showDetails.seats_name}</p>
                                        </Col>
                                    </div>
                                </div>
                                <div className="ticket">
                                    <div className="w-100 d-flex mb-2">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Booking Date')}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Booking Time')}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex mb-4">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            <p className="md">{moment.utc(showDetails.transaction_date_time).format("DD/MM/YYYY")}</p>
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="md">{moment.utc(showDetails.transaction_date_time).format("h:mm A")}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex mb-2">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            {showDetails.payment_option && <p className="sm">{t('final_ticket_page.Payment Method')}</p>}
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                            <p className="sm">{t('final_ticket_page.Booking ID')}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 d-flex mb-4">
                                        <Col xs={6} className={`${arabic ? "pr-0" : "pl-0"}`}>
                                            {showDetails.payment_option && <p className="md"><p className="md">{showDetails.payment_option}</p></p>}
                                        </Col>
                                        <Col xs={6} className={`${arabic ? "pl-0" : "pr-0"}`}>
                                <p className="md">{showDetails.bookingId}</p>
                                        </Col>
                                    </div>
                                    <div className="w-100 mt-2 mb-3 d-flex align-items-center justify-content-between">
                                        <p className="md hd">{t('final_ticket_page.YOUR BILL')}</p>
                                         <div className="exp"><p>{t('final_ticket_page.Expand')}</p></div> 
                                    </div>
                                    <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                                        <p className="md hd">{t('final_ticket_page.Total')}</p>
                                        <p className="md">{(showDetails.transaction_net_price / currency.divided_by).toFixed(2)} {arabic ? currency.currency_short_code_ar : currency.currency_short_code}</p>
                                    </div>
                                    <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                                        <p className="sm">{t('final_ticket_page.Taxes')}</p>
                                        <p className="md">{(((showDetails.fnb_taxes_in_cents + showDetails.ticket_taxes_in_cents + bookingFeeTax) / currency.divided_by)).toFixed(2)} {arabic ? currency.currency_short_code_ar : currency.currency_short_code}</p>
                                    </div>
                                    <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                                        <p className="sm">{t('final_ticket_page.Booking Fee')}</p>
                                        <p className="md">{(((showDetails.booking_fee - bookingFeeTax) / currency.divided_by)).toFixed(2)} {arabic ? currency.currency_short_code_ar : currency.currency_short_code}</p>
                                    </div>
                                    {(showDetails.transaction_before_offer_net_price - showDetails.transaction_net_price > 0) && <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                                        <p className="sm">{t('final_ticket_page.Discount')}/ {t('final_ticket_page.Offers')}</p>
                                        <p className="md">{((showDetails.transaction_before_offer_net_price - showDetails.transaction_net_price) / currency.divided_by).toFixed(2)} {arabic ? currency.currency_short_code_ar : currency.currency_short_code}</p>
                                    </div>}
                                    <div className="w-100 bt-1 d-flex align-items-center justify-content-between pt-2">
                                        <p className="sm">{t('final_ticket_page.Total')}</p>
                                        <p className="md">{(showDetails.transaction_net_price / currency.divided_by).toFixed(2)} {arabic ? currency.currency_short_code_ar : currency.currency_short_code}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div className="bottom">
                            <img src={share} className="" alt="img"/>
                        </div> 
                    </section> */}

      <section className="history-details d-flex d-md-none">
        <div className="d-block d-md-none">
          <div className="d-flex justify-content-between align-items-center px-4 pt-3 border-bottom pb-3">
            <span className="frl-22 frs-14 font-weight-bold">
              <img className="mxh-47" src={cinepolisSmall} alt="logo" />
            </span>
            <span
              onClick={() => redirectToHome()}
              className="blue-btn mxh-47 px-3 frl-16 frs-14"
            >
              HOME
            </span>
          </div>
        </div>

        <div className="gift-ticket-video  mt-2">
          <video ref={vidRef} width="330" controls autoPlay muted playsInline>
            <source src={urlStore} type="video/mp4"></source>
          </video>
        </div>

        {/* Temp */}
        {isShowTicket && (
          <div className="pb-12">
            {isgiftTicketShow && (
              <div>
                <div className="py-3 px-3 align-items-center bg-greyish gift-card-grid border-bottom">
                  {haidTicket && (
                    <div>
                      <div>
                        <label className="frl-16 frs-14">RELATION</label>
                        <Dropdown
                          onSelect={(eventKey) => onSelectrelation(eventKey)}
                          className="w-100 h-100 kumbh fw-reg bg-greyish brds-10"
                        >
                          <Dropdown.Toggle
                            className="dropdownClassic text-truncate frxl-18 frs-16 px-2"
                            id="dropdown-basic"
                          >
                            {selctedRelation ? selctedRelation : "Select"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdownItems frxl-18 frs-16">
                            {ralations &&
                              ralations.length > 0 &&
                              ralations.map((city) => (
                                <Dropdown.Item
                                  href=""
                                  className="text-truncate"
                                  eventKey={city}
                                >
                                  {city}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <label className="frl-16 frs-14">GIFT TO</label>
                        <input
                          placeholder="Please enter email Id"
                          onChange={(e) => setGiftTicketMail(e.target.value)}
                          type="text"
                          className="mxh-47 bg-greyish w-100 cc-input px-3"
                        />
                      </div>
                      <div>
                        <span
                          onClick={onProceedGiftTicket}
                          className="blue-btn w-50 mxh-47 px-3 frl-16 frs-14 mt-4"
                        >
                          PROCEED
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div style={{ marginBottom: "20px" }}>
          {isProceedGiftTicket && (
            <div className="d-flex justify-content-center pt-5">
              <span className="blue-btn mxh-47 w-56 px-3 frl-16 frs-14">
                Gift Card send successfully
              </span>
            </div>
          )}
        </div>
        {/* Temp */}

        <div className="top">
          <div className="movie-bg">
            <img
              src={
                !isImageURLNull(showDetails.movie_image_url_2)
                  ? showDetails.movie_image_url_2
                  : movieDefLand
              }
              className=""
              alt="movie"
            />
          </div>
          <div className="oi">
            <img
              src={arabic ? rightArrow : leftArrow}
              className="icon-18 mt-4 mbm-6"
              alt="arrow"
              onClick={() => history.push("/")}
            />

            <div className="ticket">
              <div className="d-flex justify-content-between py-2 flex-column">
                {/* <p className="md hd">{t('final_ticket_page.YOUR TICKET')}</p> */}
                <p className="md hd text-center bb-1">{t("ticket-confirm")}</p>
                <p className={`pt-2 lg ${arabic ? "pl-2" : "pr-2"}`}>
                  {arabic
                    ? showDetails.movie_title_ar
                    : showDetails.movie_title}{" "}
                  {showDetails.SessionDimension
                    ? "(" + showDetails.SessionDimension + ")"
                    : ""}{" "}
                  {showDetails.movie_lang
                    ? "(" +
                    showDetails.movie_lang.substring(0, 2).toUpperCase() +
                    ")"
                    : ""}
                </p>
              </div>
              <div className="w-100 d-flex mb-1">
                <Col xs={12} className={`${arabic ? "pr-0" : "pl-0"}`}>
                  {/* <p className="sm">{t('final_ticket_page.Cinema')}</p> */}
                  <p className="sm">
                    {t("final_ticket_page.Date")} &amp;{" "}
                    {t("final_ticket_page.Time")}
                  </p>
                </Col>
              </div>
              <div className="w-100 d-flex mb-2">
                <Col xs={12} className={`${arabic ? "pr-0" : "pl-0"}`}>
                  <p className="md">
                    <span>
                      {moment
                        .utc(showDetails.Showtime)
                        .format("ddd, Do, MMM, YYYY")}
                      ,{" "}
                    </span>
                    <span>
                      {moment.utc(showDetails.Showtime).format("h:mm A")}
                    </span>
                  </p>
                </Col>
              </div>

              <div className="d-flex flex-column mb-2">
                <p className="md">
                  {arabic
                    ? showDetails.cinema_name_ar
                    : titleCase(showDetails.cinema_name)}
                </p>
                <p className="md">
                  {showDetails
                    ? arabic
                      ? showDetails.ScreenNameAlt
                      : titleCase(showDetails.ScreenName)
                    : ""}
                </p>
              </div>

              <div className="w-100 d-flex mb-1">
                <Col xs={12} className={`${arabic ? "pr-0" : "pl-0"}`}>
                  <p className="sm">{t("final_ticket_page.Seats")}</p>
                </Col>
              </div>

              <div className="w-100 d-flex">
                <Col xs={12} className={`md ${arabic ? "pr-0" : "pl-0"}`}>
                  <span className="">
                    {showDetails.SessionAttribute
                      ? translateSessionAttribute(
                        arabic,
                        showDetails.SessionAttribute
                      )
                      : translateSessionAttribute(arabic, "premium")}
                  </span>{" "}
                  -<span className=""> {showDetails.seats_name}</span>
                </Col>
              </div>
            </div>
            <div className="ticket">
              <div className="d-flex flex-column align-items-center">
                <div className="qr-row mb-3 border-top-0 border-bottom-0">
                  <div className="qr-img">
                    <img src={qrcodeData} onClick={() => setSlShrink(true)} />
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <p className="sm mr-2">
                    {t("final_ticket_page.Booking ID")}:
                  </p>
                  <p className="md">{showDetails.bookingId}</p>
                </div>
                <div className="d-flex justify-content-center align-items-center d-flex flex-column">
                  <b className="md">
                    {" "}
                    Gifted By :
                    {" "}
                  </b>
                  <br></br>
                  <p className="md">
                    <b>Relation :</b>  {" "} {relation ? relation : null}
                    {" "}
                  </p>
                  <p className="md">
                    <b>Name : </b> {" "} {bookingData ? bookingData?.guest_first_name : null}
                    {bookingData ? bookingData?.user_first_name : null}
                    {" "}
                  </p>
                  <p className="md">
                    <b>Email : </b>{" "} {bookingData ? bookingData?.customer_email : null}
                    {" "}
                  </p>
                </div>
              </div>
            </div>

            {/* 
            <div className="ticket">
              <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                {showDetails.payment_option && (
                  <p className="sm pr-2">
                    {t("final_ticket_page.Payment Method")}:{" "}
                  </p>
                )}
                {showDetails.payment_option && (
                  <p className="md">
                    <p className="md">{showDetails.payment_option}</p>
                  </p>
                )}
              </div>
              <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                <p className="sm">{t("profile_page.ticket-price")}</p>
                <p className="md">
                  {(
                    (showDetails.ticket_price_in_cents_before_offer -
                      showDetails.ticket_tax_in_cents_before_offer) /
                    currency.divided_by
                  ).toFixed(2)}{" "}
                  {arabic
                    ? currency.currency_short_code_ar
                    : currency.currency_short_code}
                </p>
              </div>
              {/* {showDetails.fnb_items_count > 0 && (
                <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                  <p className="sm">
                    {t("final_ticket_page.Refreshments")} (x
                    {showDetails.fnb_items_count})
                  </p>
                  <p className="md">
                    {(
                      (showDetails.fnb_price_in_cents -
                        showDetails.fnb_taxes_in_cents) /
                      currency.divided_by
                    ).toFixed(2)}{" "}
                    {arabic
                      ? currency.currency_short_code_ar
                      : currency.currency_short_code}
                  </p>
                </div>
              )} */}
            {/*        {showDetails.fnb_items_count > 0 && (
                <>
                  <Accordion className="accordion-1 tnc-accordion ft-accordion mb-1">
                    <Card className="border-0 pb-0">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        className={`${!accordionArrow ? "show" : "hidden"}`}
                        onClick={() =>
                          accordionArrow
                            ? setAccordionArrow(false)
                            : setAccordionArrow(true)
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <span
                            className={`sm ${
                              arabic ? "text-right" : "text-left"
                            } font-weight-normal`}
                          >
                            {t("final_ticket_page.Refreshments")} (x
                            {showDetails.fnb_items_count})
                            <img alt="" src={arrowUp} className="ml-3" />
                          </span>
                          <span
                            className={`md ${
                              arabic ? "text-right" : "text-left"
                            }`}
                          >
                            {(
                              (showDetails.fnb_price_in_cents -
                                showDetails.fnb_taxes_in_cents) /
                              currency.divided_by
                            ).toFixed(2)}{" "}
                            {arabic
                              ? currency.currency_short_code_ar
                              : currency.currency_short_code}
                          </span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {showDetails &&
                            showDetails.fnbItems.map((item) => (
                              <div>
                                <div className="bg-light-black px-2 d-flex justify-content-between align-items-center mt-1">
                                  <span
                                    className={`sm ${
                                      arabic ? "text-right" : "text-left"
                                    }`}
                                  >
                                    {arabic
                                      ? item.concession_name_ar
                                      : titleCase(item.concession_name)}{" "}
                                    (x
                                    {item.quantity})
                                  </span>
                                </div>
                                {item.modifiers && item.modifiers.length > 0 && (
                                  <div className="bg-light-black pl-4">
                                    {item.modifiers.map((modifier) => (
                                      <span
                                        className={`d-block sm frm-12 frl-16 pt-2 ${
                                          arabic ? "text-right" : "text-left"
                                        }`}
                                      >
                                        {arabic
                                          ? modifier.modifier_name_ar
                                          : titleCase(
                                              modifier.modifier_name
                                            )}{" "}
                                        (x{modifier.quantity})
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </>
              )}
              <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                <p className="sm">{t("final_ticket_page.Taxes")}</p>
                <p className="md">
                  {(
                    (showDetails.transaction_before_offer_gross_price -
                      showDetails.transaction_before_offer_net_price -
                      showDetails.booking_fee +
                      bookingFeeTax) /
                    currency.divided_by
                  ).toFixed(2)}{" "}
                  {arabic
                    ? currency.currency_short_code_ar
                    : currency.currency_short_code}
                </p>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                <p className="sm">{t("final_ticket_page.Booking Fee")}</p>
                <p className="md">
                  {(
                    (showDetails.booking_fee - bookingFeeTax) /
                    currency.divided_by
                  ).toFixed(2)}{" "}
                  {arabic
                    ? currency.currency_short_code_ar
                    : currency.currency_short_code}
                </p>
              </div>
              {(showDetails.ticket_discount_price_in_cents || 0) > 0 && (
                <div className="w-100 d-flex align-items-center justify-content-between mb-1">
                  <p className="sm">
                    {t("final_ticket_page.Discount")}/{" "}
                    {t("final_ticket_page.Offers")}
                  </p>
                  <p className="md">
                    {(
                      (showDetails.ticket_discount_price_in_cents || 0) /
                      currency.divided_by
                    ).toFixed(2)}{" "}
                    {arabic
                      ? currency.currency_short_code_ar
                      : currency.currency_short_code}
                  </p>
                </div>
              )}
              <div className="w-100 bt-1 d-flex align-items-center justify-content-between pt-2">
                <p className="sm">{t("final_ticket_page.Total")}</p>
                <p className="md">
                  {(
                    showDetails.transaction_net_price / currency.divided_by
                  ).toFixed(2)}{" "}
                  {arabic
                    ? currency.currency_short_code_ar
                    : currency.currency_short_code}
                </p>
              </div>
            </div> */}
            {country_data && country_data.country_short_code === "BH" && (
              <p className="fp-covid-note">
                {/* {t("final_ticket_page.covid_note")} */}
              </p>
            )}
            {country_data && country_data.country_short_code === "AE" && (
              <>
                <p className="fp-covid-note pt-5 w-100 px-3">
                  {/* {t("final_ticket_page.fnb_note_title")} */}
                </p>
                <p className="fp-covid-note pt-1 w-100 px-3">
                  {t("final_ticket_page.fnb_note_body")}
                </p>
              </>
            )}
          </div>
        </div>
      </section>

      <Modal
        show={slShrink}
        onHide={handleSlShrinkModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="sl-modal for-btn large-zindex"
      >
        <div className="wrapper">
          <div className="bg-light-black">
            <Modal.Header
              closeButton
              className={`p-0 border-0 pt-2 ${arabic ? "pl-2" : "pr-2"}`}
            ></Modal.Header>
            <div className="d-flex justify-content-center align-items-center h-400 py-5 w-100">
              <img src={qrcodeData} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default withNamespaces()(FinalTicketContainer);
