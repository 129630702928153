export const createQueryString = (payload = {}) => {
    let query = "";
    if (Object.keys(payload).length > 0) {
        let urlparams = new URLSearchParams();
        for(const key in payload) {
            if(payload[key]) {
                urlparams.append(key, payload[key]);
            }
        }
        if([...urlparams.entries()].length > 0) {
            query += `?${urlparams.toString()}`;
        }
    }
    return query;
}